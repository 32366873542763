import axios from "axios";


const uploadApi = {
  uploadImg: uploadImg,
  uploadFile: uploadFile,
}

export function uploadImg(params) {
  return request({
    url: '/global/uploadImg',
    method: 'post',
    data: params
  })
}
export function uploadFile(url, formData) {
  let imgUrl;
  let config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  if (process.env.NODE_ENV === "development") {
    url = "/api/global/uploadFile";
  }
  // debugger
  axios
    .post(url, formData, config)
    .then((res) => {
      console.log("====>" + res.data.status);
      if (res.data.status === 200) {
        imgUrl = res.data.message
        console.log("====>" + imgUrl);
      }
    })
    .catch((res) => {});

}


export default uploadApi
