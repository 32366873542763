<template>
  <div>
    <div class="top">
      <el-button
        v-if="this.appId"
        @click="showAddItem(appId)"
        size="medium"
        type="success"
      >
        <i class="el-icon-plus" />&nbsp;添加推送通知
      </el-button>
    </div>
    <div class="app-container">
      <el-table
        :data="list"
        @sort-change="handleSortChange"
        border
        element-loading-text="Loading"
        fit
        highlight-current-row
        row-class-name="table-row"
        v-loading="this.loading"
      >
        <el-table-column label="推送ID">
          <el-table-column :render-header="customPushId">
            <template slot-scope="scope">{{ scope.row.id }}</template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="推送名称">
          <el-table-column :render-header="customRenderTitleHeader">
            <template slot-scope="scope">{{ scope.row.pushName }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="!this.appId && isApp == '0'" label="推送应用">
          <el-table-column :render-header="customApp">
            <template slot-scope="scope">{{ scope.row.appName }} </template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-else-if="!this.appId" label="推送主题">
          <el-table-column :render-header="customTheme">
            <template slot-scope="scope">{{ scope.row.themeName }} </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="推送类别">
          <el-table-column :render-header="customIsApp">
            <template slot-scope="scope"
              >{{ scope.row.isApp | filterIsApp }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="推送方式（手动、自动）">
          <el-table-column :render-header="customRenderPushType">
            <template slot-scope="scope">{{
              scope.row.pushType | filterPush
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="推送样式">
          <el-table-column :render-header="customStyleContent">
            <template slot-scope="scope">{{ scope.row.styleContent }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="isApp == 0" label="推送人数">
          <el-table-column :render-header="customRenderCategoryHeader">
            <template slot-scope="scope">{{ scope.row.pushPeople }}</template>
          </el-table-column>
        </el-table-column>

        <el-table-column
          v-if="isApp == 0"
          label="推送到达数"
          sortable="custom"
          :label-width="formLabelWidth"
        >
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.pushCreate }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="isApp == 0" label="推送展示数" sortable="custom">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.pushShow }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column v-if="isApp == 0" label="推送点击数" sortable="custom">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.pushOnClick }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          label="自动推送时间"
          prop="auto_push_time"
          sortable="custom"
        >
          <el-table-column>
            <template slot-scope="scope">{{
              scope.row.autoPushTime | formatDateToSecond
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column
          v-if="isApp == 0"
          label="更新时间"
          prop="update_at"
          sortable="custom"
        >
          <el-table-column :render-header="customUpdateAt">
            <template slot-scope="scope">{{
              scope.row.updateAt | formatDateToSecond
            }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="状态">
          <el-table-column :render-header="customPushStatus">
            <template slot-scope="scope">{{
              scope.row.pushStatus | filterStatus
            }}</template>
          </el-table-column>
        </el-table-column>
        <!-- <el-table-column label="推送种类">
          <el-table-column :render-header="customPushKind">
            <template slot-scope="scope">{{
              scope.row.pushKind | filterPushKind
            }}</template>
          </el-table-column> -->
        <!-- </el-table-column> -->
        <el-table-column
          header-align="center"
          label="操作"
          :label-width="formLabelWidth"
        >
          <el-table-column align="center" prop="created_at">
            <template slot-scope="scope">
              <el-button
                v-if="
                  AppInfo.isApp == 0 || AppInfo.isApp == 4 || AppInfo.isApp == 3
                "
                @click="showInfo(scope.row.id)"
                size="mini"
                type="view"
                style="float: left; margin: 0 0 1% 0"
                >查看</el-button
              >
              <el-button
                v-if="AppInfo.isApp == 2 && userName == 'natasha.liao'"
                @click="update(scope.row.id, scope.row.isApp)"
                size="mini"
                type="view"
                style="float: left; margin: 0 0 1% 0"
                >更新</el-button
              >
              <el-button
                @click="copy(scope.row.id)"
                size="mini"
                type="view"
                style="float: left; margin: 0 0 1% 0"
                >复制
              </el-button>
              <!-- <el-button
                v-else
                @click="update(scope.row.id)"
                size="mini"
                type="view"
                >更新
              </el-button> -->
              <el-button
                v-if="scope.row.pushType != 2 || userName == 'natasha.liao'"
                @click="deletePush(scope.row.id)"
                style="float: left; margin: 0 0 1% 0"
                size="mini"
                type="view"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div>
        <el-pagination
          class="page"
          :current-page="AppInfo.pageNum"
          :page-size="AppInfo.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next"
          style="float: right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import pushApi from "@/apis/pushApi";
import appApi from "@/apis/appApi";
import themeApi from "@/apis/themeApi";
import { Message, MessageBox } from "element-ui";
let themes = null;

export default {
  components: {
    pushApi,
    appApi,
    themeApi,
  },
  inject: ["reload"],
  beforeCreate() {
    // 将vue实例赋值给全局变量app
    themes = this;
  },
  data() {
    return {
      formLabelWidth: "100px",
      listLoading: true,
      loading: true,
      list: [],
      role: [],
      isApp: this.$route.params.isApp || "",
      form: {
        appList: [],
        themeList: [],
      },
      userName: "",
      listInfo: {
        pageSize: 999999,
        pageNum: 1,
        order: "DESC",
        attribute: "create_at",
      },
      titleSearchFlag: -1,
      pushIdSearchFlag: -1,
      updateAtSearchFlag: -1,
      total: 1000,
      value: "",
      AppInfo: {
        pageSize: 10,
        pageNum: 1,
        pushName: "",
        pushType: "",
        pushId: this.$route.params.pushId || "",
        notifyType: "",
        isApp: this.$route.params.isApp || "",
        pushKind: "",
        pushStatus: "",
        order: "DESC",
        attribute: "create_at",
        updateAt: "",
        appId: this.$route.params.appId || "",
        themeKey: this.$route.params.key || "",
        // appId: 3,
        styleContent: "",
      },
      options: [],
      appName: this.$route.params.appName || "",
      appId: this.$route.params.appId,
    };
  },
  created() {
    this.userName = localStorage.getItem("username");
    this.role = localStorage.getItem("role").split(",");
    console.log("name" + this.userName);
    console.log("role==>" + localStorage.getItem("role"));
    this.AppInfo.isApp = this.$route.params.isApp || "";
    this.isApp = this.$route.params.isApp || "";
    debugger;
    if (this.role != null && this.role != "") {
      if (this.isApp == 0) {
        this.getAppList();
        this.$forceUpdate();
        // this.customApp();
      } else {
        this.getThemes();
        this.$forceUpdate();
        // this.customTheme();
      }
      this.fetchData();
    } else {
      this.$router.push(`/`);
    }
  },
  watch: {
    // 路由发生变化页面刷新
    $route(to, from) {
      // this.$data = this.$options.data();
      // this.form = this.$options.data().form;
      this.AppInfo.isApp = this.$route.params.isApp || "";
      this.isApp = this.$route.params.isApp || "";
      if (this.AppInfo.isApp == "0") {
        this.getAppList();
        debugger;
      } else {
        debugger;
        this.getThemes();
      }
      this.fetchData();
      console.log("appList..>" + this.appList.length);
      console.log("themeList..>" + this.themeList.length);
    },
  },
  filters: {
    formatData: function (value) {
      if (value == null || value == "") {
        return "";
      }
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d}");
    },
    formatDateToSecond: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    filterPush(value) {
      switch (value) {
        case 1:
          return "手动";
        case 2:
          return "自动";
        default:
          return "";
      }
    },
    filterIsApp(value) {
      switch (value) {
        case 0:
          return "普通应用";
        case 1:
          return "主题推送";
        case 2:
          return "每日推送模版";
        case 3:
          return "主题推送模版";
        case 4:
          return "每日推送";
        case 5:
          return "免费Hint";
        default:
          return "unknown";
      }
    },
    filterRatio(value) {
      switch (value) {
        case 1:
          return "10%";
        case 2:
          return "20%";
        case 3:
          return "50%";
        case 4:
          return "100%";
        default:
          return "";
      }
    },
    filterThemeKey(value) {
      // switch (value) {
      //   case 1:
      //     return "10%";
      //   case 2:
      //     return "20%";
      //   case 3:
      //     return "50%";
      //   case 4:
      //     return "100%";
      //   default:
      //     return "";
      // }
      themes.form.themeList.map((item) => {
        // console.log("form.themeList." + item.key);
        if (value == item.key) {
          console.log("form.themeList." + item.name);
          return item.name;
        }
      });
    },
    filterNotify(value) {
      switch (value) {
        case 1:
          return "无";
        case 2:
          return "通知类型－自定义样式";
        case 3:
          return "通知类型－单张图片样";
        default:
          return "全部";
      }
    },
    filterStatus(value) {
      switch (value) {
        case 0:
          return "创建完成";
        case 55:
          return "发现错误";
        case 50:
          return "推送中";
        case 60:
          return "推送完成";
        case 61:
          return "推送失败";
        case 40:
          return "生成推送用户完成状态";
        case 41:
          return "生成推送用户失败状态";
        default:
          return "创建完成";
      }
    },
    filterPushKind(value) {
      switch (value) {
        case 1:
          return "测试推送";
        case 2:
          return "普通推送";
        case 3:
          return "分国家推送";
        case 4:
          return "分语言推送";

        default:
          return "全部";
      }
    },
  },
  methods: {
    deletePush(id) {
      MessageBox.confirm("确定删除该条推送?", "提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>
        pushApi
          .deletePush({ pushId: id })
          .then((response) => {
            if (response.status == 200) {
              this.fetchData();
            }
          })
          .catch((res) => {
            console.log(res);
          })
      );
    },
    getAppList() {
      appApi
        .getList(this.listInfo)
        .then((response) => {
          debugger;
          if (response.status == 200) {
            var list = [{ appId: null, appName: "全部" }];
            this.form.appList = Array.from(list.concat(response.data.appList));
            this.listLoading = false;
          }
        })
        .catch((response) => {
          this.$router.push({ path: "/" });
        });
    },
    getThemeName(value) {},
    getThemes() {
      var listInfo = {
        pageSize: 999999,
        pageNum: 1,
        order: "ASC",
        attribute: "id",
      };
      themeApi
        .getList(listInfo)
        .then((response) => {
          var list1 = [{ key: null, name: "全部" }];
          this.form.themeList = Array.from(
            list1.concat(response.data.themeList)
          );
          this.total = response.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    copy(appId) {
      console.log(appId);
      const pushId = appId;
      console.log("=====>121212122112" + pushId);
      var flag = true;
      this.$router.push(`/copyPushInfo/${pushId}/${flag}`);
    },
    fetchData() {
      const app = {
        appId: this.appId,
      };
      // this.AppInfo.outPush = app;
      // console.log("====" + this.AppInfo.outPush.appId);
      // this.AppInfo.updateAt = new Date(this.AppInfo.updateAt).getTime() / 1000;
      pushApi
        .getList(this.AppInfo)
        .then((response) => {
          debugger;
          this.list = response.data.pushList;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((response) => {
          console.log(response);
          this.$router.push({ path: "/" });
        });
    },
    update(pushId, isApp) {
      this.$router.push(`/updatePush/${pushId}/${isApp}`);
    },
    showInfo(pushId) {
      this.$router.push(`/getPushInfo/${pushId}`);
    },
    reloadDate() {
      if (this.$route.params.appName == "undefined") {
        this.reload();
      }
      return;
    },
    showAddItem(appId) {
      this.$router.push(`/createPush/${appId}/0`);
    },
    handleSizeChange(val) {
      this.AppInfo.pageSize = val;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.AppInfo.pageNum = val;
      this.fetchData();
    },

    handleSortChange({ column, prop, order }) {
      const sortOrder = order === "ascending" ? "ASC" : "DESC";
      this.AppInfo.attribute = prop;
      this.AppInfo.order = sortOrder;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
    customRenderCategoryHeader() {},
    customOnlineAtHeader() {},
    customUpdateAt(h) {
      const $vm = this;
      return h("el-date-picker", {
        props: {
          type: "date",
          value: $vm.AppInfo.updateAt,
          size: "mini",
          "value-format": "yyyy-MM-dd HH:mm:ss",
          placeholder: "选择日期",
          clearable: true,
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.updateAt = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.updateAtSearchFlag);
            $vm.pushIdSearchFlag = setTimeout(() => {
              var date1 = value + "";
              console.log("===>" + date1);
              $vm.AppInfo.updateAt = new Date(date1).getTime() / 1000;
              console.log("===>" + $vm.AppInfo.updateAt);

              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customApp(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.appId,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.appId = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          this.form.appList.map((item) => {
            console.log("===>" + item.appName);
            return h("el-option", {
              props: {
                value: item.appId,
                label: item.appName,
                placeholder: item.appName,
              },
            });
          }),
        ]
      );
    },
    customIsApp(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.isApp,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.isApp = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 0,
              label: "普通应用",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "主题推送",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "每日推送模版",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "主题推送模版",
            },
          }),
          h("el-option", {
            props: {
              value: 4,
              label: "每日推送",
            },
          }),
          h("el-option", {
            props: {
              value: 5,
              label: "免费Hint",
            },
          }),
        ]
      );
    },
    customTheme(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.themeKey,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.themeKey = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          this.form.themeList.map((item) => {
            console.log("===>" + item.name);
            return h("el-option", {
              props: {
                value: item.key,
                label: item.name,
                placeholder: item.name,
              },
            });
          }),
        ]
      );
    },
    customNotifyType(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.appName,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.notifyType = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "无",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "通知类型－单张图片样式",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "通知类型－自定义样式",
            },
          }),
        ]
      );
    },
    customPushStatus(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.pushStatus,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.pushStatus = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 0,
              label: "创建完成",
            },
          }),
          h("el-option", {
            props: {
              value: 50,
              label: "推送中",
            },
          }),
          h("el-option", {
            props: {
              value: 60,
              label: "推送完成",
            },
          }),
          h("el-option", {
            props: {
              value: 61,
              label: "推送失败",
            },
          }),
        ]
      );
    },
    customPushKind(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.pushKind,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.pushKind = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "测试推送",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "普通推送",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "分国家推送",
            },
          }),
          h("el-option", {
            props: {
              value: 4,
              label: "分语言推送",
            },
          }),
        ]
      );
    },
    customPushId(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.pushId,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.pushId = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.pushIdSearchFlag);
            $vm.pushIdSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customStyleContent(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.styleContent,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.styleContent = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.pushIdSearchFlag);
            $vm.pushIdSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customRenderTitleHeader(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.pushName,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.pushName = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.titleSearchFlag);
            $vm.titleSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customRenderPushType(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.pushType,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.pushType = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "手动",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "自动",
            },
          }),
        ]
      );
    },
  },
};
</script>
<style scoped>
.top {
  position: relative;
  float: left;
  margin: 1% 10%;
}
.app-container {
  margin-top: 2%;
  margin: 2% 5% 0 5%;
  /* border: 0px solid black; */
  /* width: 70%; */
  /* visibility: none; */
}
.page {
  /* width: 68%; */
  /* position: relative; */
  /* margin: 1% 5%; */
}
</style>