import request from '@/utils/request'



const pushAPi = {
  getList: getList,
  createPush: createPush,
  updatePush: updatePush,
  getPushById: getPushById,
  directPush: directPush,
  testPush: testPush,
  getThemePushByKey: getThemePushByKey,
  getCategoryList: getCategoryList,
  getActivityList: getActivityList,
  getNewList: getNewList,
  deletePush: deletePush
}
export function getCategoryList(params) {
  return request({
    url: '/coloring/getCategoryList',
    method: 'get',
    params
  })
}

export function getActivityList(params) {
  return request({
    url: '/coloring/getActivityList',
    method: 'get',
    params
  })
}
export function getNewList(params) {
  return request({
    url: '/coloring/getNewList',
    method: 'get',
    params
  })
}


export function getList(params) {
  return request({
    url: '/push/getPushList',
    method: 'get',
    params
  })
}
export function getPushById(params) {
  return request({
    url: '/push/getPushById',
    method: 'get',
    params
  })
}
export function getThemePushByKey(params) {
  return request({
    url: '/push/getThemePushByKey',
    method: 'get',
    params
  })
}
export function createPush(params) {
  return request({
    url: '/push/createOrUpdate',
    method: 'post',
    data: params
  })
}

export function updatePush(params) {
  return request({
    url: '/push/createOrUpdate',
    method: 'post',
    data: params
  })
}

export function testPush(params) {
  return request({
    url: '/push/testPush',
    method: 'get',
    params
  })
}
export function directPush(params) {
  return request({
    url: '/push/directPush',
    method: 'get',
    params
  })
}

export function deletePush(params) {
  return request({
    url: '/push/deletePush',
    method: 'PUT',
    params
  })
}



export default pushAPi
