<template>
  <div class="mainConten">
    <h2 style="margin: 0 0 2% -30%" v-if="id == 'undefined'">增加App应用</h2>
    <h2 v-else>修改App应用</h2>
    <el-form :model="form" ref="form">
      <el-form-item
        :label-width="formLabelWidth"
        label="Id"
        v-if="id != 'undefined'"
      >
        <el-input
          :disabled="true"
          auto-complete="off"
          class="form-input"
          size="small"
          v-model="id"
        />
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="名称" prop="name">
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="请输入名称..."
          size="small"
          v-model="form.appName"
        />
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="包名" prop="name">
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="请输入包名名称..."
          size="small"
          v-model="form.packageName"
        />
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="appKey" prop="name">
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="请输入appKey..."
          size="small"
          v-model="form.appKey"
        />
      </el-form-item>
      <el-form-item :label-width="formLabelWidth" label="serveKey" prop="name">
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="serverKey..."
          size="small"
          v-model="form.serverKey"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="firebaseId"
        prop="name"
      >
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="serverKey..."
          size="small"
          v-model="form.firebaseId"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="description"
        prop="description"
      >
        <el-input
          auto-complete="off"
          class="form-input"
          placeholder="请输入description..."
          size="small"
          v-model="form.description"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="tableName(后端提供）"
        prop="name"
      >
        <el-input
          :disabled="flag"
          auto-complete="off"
          class="form-input"
          placeholder="请输入表名称...t_user_产品_平台"
          size="small"
          v-model="form.tableName"
        />
      </el-form-item>
      <el-form-item
        :label-width="formLabelWidth"
        label="app操作系统(必填）"
        prop="app_os"
      >
        <el-radio :disabled="flag" v-model="form.appOs" :label="labelNum1"
          >Android</el-radio
        >
        <el-radio :disabled="flag" v-model="form.appOs" :label="labelNum2"
          >ios</el-radio
        >
      </el-form-item>
      <el-form-item label="权限">
        <el-select
          class="trigger"
          v-model="form.roleGroup"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in roleGroups"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label-width="formLabelWidth" label="icon" prop="icon">
        <el-upload
          :width="130"
          :height="130"
          class="avatar-uploader"
          action="21"
          :http-request="uploadImage"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="form.appIcon" :src="form.appIcon" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item :label-width="formLabelWidth">
        <el-button @click="save" type="primary">提交</el-button>
        <el-button @click="goBack">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<style lang="scss" scoped>
.mainConten {
  width: 30%;
  margin: 5% 15%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.tag-container {
  margin: 40px;
  .info-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
    tr > th {
      width: 30%;
    }
    tr > th,
    tr > td {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
  }
}
</style>

<script>
// import { parseTime } from '@/utils';
import appApi from "@/apis/appApi";
import uploadApi from "@/apis/uploadApi";
import { Message } from "element-ui";
import axios from "axios";
export default {
  components: {
    appApi,
    // Upload,
    uploadApi,
    // moduleApi
  },

  filters: {
    stateFilter: function (value) {
      switch (value) {
        case 0:
          return "无效";
        case 1:
          return "正常";
        default:
          return "";
      }
    },
    propertyFilter: function (value) {
      switch (value) {
        case 0:
          return "public";
        case 1:
          return "protect";
        case 2:
          return "private";
        default:
          return value;
      }
    },
    formatData: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
  },
  data() {
    return {
      id: this.$route.params.id || "",
      loading: false,
      flag: false,
      formLabelWidth: "150px",
      labelNum5: 5,
      labelNum4: 4,
      labelNum3: 3,
      labelNum2: 2,
      labelNum1: 1,
      userName: "",
      form: {
        id: "",
        appId: "",
        appName: "",
        packageName: "",
        serverKey: "",
        appKey: "",
        appIcon: "",
        description: "",
        appOs: 1,
        tableName: "",
        firebaseId: "",
        roleGroup: "paint",
      },
      roleGroups: [
        {
          label: "paint_android",
          value: "paint",
        },
        {
          label: "paint_ios",
          value: "paint_ios",
        },
        {
          label: "themekit_ios",
          value: "themekit_ios",
        },
        {
          label: "themekit_android",
          value: "themekit_android",
        },
        {
          label: "other",
          value: "other",
        },
        {
          label: "Z_intern",
          value: "Z_intern",
        },
      ],
      rules: {
        name: [
          {
            type: "string",
            required: true,
            message: "请输入",
            trigger: "blur",
          },
        ],
        lines: [
          { required: true, message: "请定义显示行数", trigger: "blur" },
          { validator: this.checkLinesNum, trigger: "blur" },
        ],
        status: [{ required: true, message: "请选择状态", trigger: "blur" }],
      },
    };
  },
  created() {
    this.loading = true;
    if (this.id != "undefined") {
      this.userName = localStorage.getItem("username");
      this.role = localStorage.getItem("role");
      if (
        this.userName == "natasha.liao" ||
        this.userName == "admin" ||
        this.userName == "wei.He"
      ) {
        this.flag = false;
      } else {
        this.flag = true;
      }
      // this.flag = true;
      this.fetchData();
    } else {
      this.userName = localStorage.getItem("username");
      if (
        this.userName == "natasha.liao" ||
        this.userName == "admin" ||
        this.userName == "wei.He"
      ) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    }
    this.loading = false;
  },
  methods: {
    beforeAvatarUpload() {
      this.loading = true;
      if (this.dimension || this.fileSize) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const $vm = this;

        const checkFileSize = () => {
          // fileSize 沒設定不需要檢查
          return !$vm.fileSize || file.size / 1024 < $vm.fileSize;
        };

        const checkDimension = () => {
          //dimension 沒設定不需要檢查
          if (!this.dimension) {
            return true;
          }
          const hasOperator = this.dimension.indexOf("<") >= 0;
          if (hasOperator) {
            const width = Number(
              this.dimension.split("|")[0].replace(/</gi, "")
            );
            const height = Number(
              this.dimension.split("|")[1].replace(/</gi, "")
            );

            return img.width <= width && img.height <= height;
          } else {
            const width = Number(this.dimension.split("|")[0]);
            const height = Number(this.dimension.split("|")[1]);
            return img.width === width && img.height === height;
          }
        };

        return new Promise((resolve, reject) => {
          img.onload = function () {
            if (checkDimension() && checkFileSize()) {
              resolve();
            } else {
              Message({
                message: `图片${file.name}尺寸不符合要求，请重新上传`,
                type: "error",
                duration: 5 * 1000,
              });
              $vm.loading = false;
              reject();
            }
          };
        });
      }
    },
    uploadImage(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.appIcon = res.data.message;
            console.log("====>" + this.form.appIcon);
          }
        })
        .catch((res) => {});
    },
    handleAvatarSuccess() {
      this.loading = false;
      if (res.status === 200) {
      } else {
        Message({
          message: "上传失败",
          type: "success",
          duration: 5 * 1000,
        });
      }
    },
    fetchData() {
      appApi
        .getById({ appId: this.id })
        .then((response) => {
          if (response.status === 200) {
            this.id = response.data.id;
            this.form.id = response.data.id;
            this.form.appId = response.data.appId;
            this.form.appName = response.data.appName;
            this.form.packageName = response.data.packageName;
            this.form.appKey = response.data.appKey;
            this.form.serverKey = response.data.serverKey;
            this.form.appIcon = response.data.appIcon;
            this.form.appOs = response.data.appOs;
            this.form.tableName = response.data.tableName;
            this.form.description = response.data.description;
            this.form.firebaseId = response.data.firebaseId;
            this.form.roleGroup = response.data.roleGroup;
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },

    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.id == "undefined") {
            // 增加
            const appModel = Object.assign({}, this.form);

            delete appModel["id"];
            appApi.createApp(appModel).then((response) => {
              if (response.status === 200) {
                Message({
                  message: response.message,
                  type: "success",
                  duration: 5 * 1000,
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 500);
              } else {
                Message({
                  message: response.message,
                  type: "error",
                  duration: 5 * 1000,
                });
              }
            });
          } else {
            // 修改
            const form1 = Object.assign({}, this.form);

            appApi.updateApp(form1).then((response) => {
              if (response.status === 200) {
                Message({
                  message: response.message,
                  type: "success",
                  duration: 5 * 1000,
                });
                setTimeout(() => {
                  this.$router.go(-1);
                }, 500);
              } else {
                Message({
                  message: response.message,
                  type: "error",
                  duration: 5 * 1000,
                });
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    checkLinesNum(rule, value, callback) {
      const numStyle = /^[0-9]*$/;
      if (!numStyle.test(value)) {
        callback(new Error("只能输入数字"));
      } else if (this.form.lines <= 0) {
        callback(new Error("展示行数必须大于0"));
      } else if (this.form.lines >= 4) {
        callback(new Error("展示行数太多，不合理"));
      } else {
        callback();
      }
    },

    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
