<template>
  <div>
    <i
      v-if="type !== 'file' && initPreview && showDelete"
      :style="{ left: `${width}px` }"
      class="el-icon-error delete-img-icon"
      @click="handleImgDelete()"
    />
    <el-upload
      :show-file-list="false"
      :on-success="success"
      :on-error="error"
      :on-progress="start"
      :before-upload="beforeAvatarUpload"
      :multiple="true"
      :action="action"
      :name="name"
      :index="index"
      :accept="accept"
      :drag="drag"
      :style="{ height: `${height}px`, width: `${width}px` }"
      class="avatar-uploader"
    >
      <template v-if="type !== 'file'">
        <template v-if="!loading">
          <div
            :style="{ width: `${width}px`, height: `${height}px` }"
            class="wrap"
          >
            <img v-if="initPreview" :src="initPreview" class="avatar" />
            <i
              v-else
              :style="{
                width: `${width}px`,
                height: `${height}px`,
                'line-height': `${height}px`,
              }"
              class="el-icon-plus avatar-uploader-icon"
            />
          </div>
        </template>
        <template v-else>
          <div
            v-loading="loading"
            :style="{ width: `${width}px`, height: `${height}px` }"
            class="loading-area"
          />
        </template>
      </template>
      <template v-else>
        <el-input v-loading="loading" :value="fileName" class="fileName">
          <template slot="append">
            <el-button type="primary">选择文件</el-button>
          </template>
        </el-input>
      </template>
    </el-upload>
  </div>
</template>
<style>
.el-upload-dragger {
  width: 100%;
  height: 100%;
  border: none;
}
</style>

<style lang="scss" scoped>
.wrap {
  border: 1px dashed #d9d9d9;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  font-size: 0;
}
.delete-img-icon {
  font-size: 14px;
  color: red;
  position: absolute;
}
.loading-area {
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  position: relative;
  div {
    width: 100%;
    height: 100%;
  }
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  font-size: 28px;
  color: #8c939d;
  text-align: center;
}
.avatar-uploader-icon:hover {
  border-color: #409eff;
}
.avatar {
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.fileName {
  font-size: 16px;
  line-height: 16px;
  color: #000;
  text-align: center;
}
</style>
<script>
import { Message } from "element-ui";
import uploadApi from "@/apis/uploadApi";
import appApi from "@/apis/appApi";
export default {
  components: {
    uploadApi,
    appApi,
  },
  props: {
    name: {
      type: String,
      default: "file",
    },
    onDelete: {
      type: Function,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: null,
    },
    deleteImg: {
      type: Function,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
    action: {
      type: String,
      default: "/customTheme/picUpload",
    },

    height: {
      type: Number,
      default: 100,
    },
    initPreview: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/jpeg,image/gif,image/png,audio/mpeg",
    },
    type: {
      type: String,
      default: "img",
    },
    fileName: {
      type: String,
      default: "",
    },
    index: {
      type: [String, Number],
      default: "",
    },
    beforeUpload: {
      type: Function,
      default: undefined,
    },
    dimension: {
      type: String,
      default: "",
    },
    fileSize: {
      type: Number,
      default: 0,
    },
    drag: {
      type: Boolean,
      default: true,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    if (this.initPreview) {
      const img = new Image();
      img.src = this.initPreview;
      img.onload = () => {
        this.loading = false;
      };
    }
  },
  created() {},
  methods: {
    error() {
      this.loading = false;
    },
    success(resp) {
      if (resp.status === 200) {
        if (this.onSuccess) {
          if (this.index !== "") {
            this.onSuccess(resp, this.index);
          } else {
            this.onSuccess(resp);
          }
        }
      }
      this.loading = false;
    },
    handleImgDelete() {
      this.onDelete();
    },
    start() {
      this.loading = true;
    },
    uploadImage(param) {
      const file = new FormData();
      file.append("file", param.file);
      console.log("12321312312" + param);
      uploadApi
        .uploadImg(file)
        .then((res) => {
          console.log("上传图片成功");
        })
        .catch((response) => {
          console.log("图片上传失败");
        });
    },
    async beforeAvatarUpload(file) {
      this.loading = true;
      if (this.dimension || this.fileSize) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const $vm = this;

        const checkFileSize = () => {
          // fileSize 沒設定不需要檢查
          return !$vm.fileSize || file.size / 1024 < $vm.fileSize;
        };

        const checkDimension = () => {
          //dimension 沒設定不需要檢查
          if (!this.dimension) {
            return true;
          }
          const hasOperator = this.dimension.indexOf("<") >= 0;
          if (hasOperator) {
            const width = Number(
              this.dimension.split("|")[0].replace(/</gi, "")
            );
            const height = Number(
              this.dimension.split("|")[1].replace(/</gi, "")
            );

            return img.width <= width && img.height <= height;
          } else {
            const width = Number(this.dimension.split("|")[0]);
            const height = Number(this.dimension.split("|")[1]);
            return img.width === width && img.height === height;
          }
        };

        return new Promise((resolve, reject) => {
          img.onload = function () {
            if (checkDimension() && checkFileSize()) {
              resolve();
            } else {
              Message({
                message: `图片${file.name}尺寸不符合要求，请重新上传`,
                type: "error",
                duration: 5 * 1000,
              });
              $vm.loading = false;
              reject();
            }
          };
        });
      }
    },
  },
};
</script>