<template>
  <div class="top">
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送基本信息</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>应用名称</th>
              <td>{{ info.appNames }}</td>
            </tr>
            <tr>
              <th>推送主题</th>
              <td>{{ info.themeName }}</td>
            </tr>
            <tr>
              <th>推送名称</th>
              <td>{{ info.pushName }}</td>
            </tr>
            <tr>
              <th>推送人数</th>
              <td>{{ info.pushPeople }}</td>
            </tr>

            <!-- <tr>
              <th>预装通知</th>
              <td>否</td>
            </tr>

            <tr>
              <th>手机本地开始时间</th>
              <td>{{ info.startTime | formatDateToSecond }}</td>
            </tr>
            
            <tr>
              <th>手机本地结束时间</th>
              <td>{{ info.endTime | formatDateToSecond }}</td>
            </tr>
            <tr>
              <th>服务器推送开始时间</th>
              <td>{{ info.startTime | formatDateToSecond }}</td>
            </tr>
            
            <tr>
              <th>服务器推送结束时间</th>
              <td>{{ info.endTime | formatDateToSecond }}</td>
             </tr> -->
            <tr>
              <th>推送数据格式</th>
              <td>{{ info.badge | filterBadge }}</td>
            </tr>

            <tr>
              <th>推送样式/推送比例</th>
              <td>{{ this.styleContent }}</td>
            </tr>
            <tr>
              <th>icon</th>
              <td>
                <div class="flex-img" v-if="info.pushIcon">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushIcon" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>banner</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>banner2</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner2">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner2" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>banner3(720*128,标题左)</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner3">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner3" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>banner4(720*128,标题中)</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner4">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner4" />
                  </div>
                </div>
              </td>
            </tr>
            <tr></tr>
            <tr>
              <th>banner5(720*128,标题中)</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner5">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner5" />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>banner6(ThemeKitIOS)</th>
              <td>
                <div class="flex-img" v-if="info.pushBanner6">
                  <div class="img-div" v-viewer>
                    <img :src="info.pushBanner6" />
                  </div>
                </div>
              </td>
              <!-- <tr>
              <th>震动提醒</th>
              <td>否</td>
            </tr>
            <tr>
              <th>铃声提醒</th>
              <td>否</td>
            </tr> -->
            </tr>

            <tr>
              <th>推送方式</th>
              <td>{{ info.pushType | filterPush }}</td>
            </tr>
            <tr>
              <th>推送比例</th>
              <td>{{ info.pushRatio | filterRatio }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送标题</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>语言</th>
              <th>值</th>
            </tr>
            <tr v-for="(item, index) in info.pushTitles" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送副标题</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>语言</th>
              <th>值</th>
            </tr>
            <tr v-for="(item, index) in info.pushSubTitles" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 筛选用户条件</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>类型</th>
              <th>值</th>
            </tr>
            <tr v-for="(item, index) in info.countries" :key="index">
              <td>国家</td>
              <td>{{ item }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送触发条件</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>触发条件</th>
              <td>立即触发</td>
            </tr>
            <tr>
              <th>键盘激活</th>
              <td>全集</td>
            </tr>
            <tr>
              <th>网络状态</th>
              <td>no network limit</td>
            </tr>
            <tr>
              <th>强制推送</th>
              <td>否</td>
            </tr>
            <tr>
              <th>触发延时</th>
              <td>0秒</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送目标信息</span>
        <div class="info-cantainer" v-loading="loading">
          <table class="info-table">
            <tr>
              <th>跳转目标</th>
              <th>值</th>
            </tr>
            <tr v-for="(item, index) in info.targetInfo" :key="index">
              <td>{{ index | filterTarget }}</td>
              <td>{{ item | filterValue }}</td>
            </tr>
          </table>
        </div>
      </el-tab-pane>
    </el-tabs>

    <div v-if="isApp != 4">
      <el-button @click="goBack()" class="goBackBtn" size="mini" type="primary">
        <i class="el-icon-back" />&nbsp;返回
      </el-button>
      <el-button
        @click="goToUpdate()"
        class="goUpdateBtn"
        size="mini"
        type="primary"
        v-if="info.status != '删除'"
        >更新</el-button
      >
      <el-button
        @click="testPush(0)"
        class="goBackBtn"
        size="mini"
        type="primary"
      >
        GCM测试
      </el-button>
      <el-button
        @click="forkTest(1)"
        class="goBackBtn"
        size="mini"
        type="primary"
      >
        手动测试
      </el-button>
      <el-button
        v-if="userName != 'honglin.Gan'"
        @click="directPush()"
        class="goBackBtn"
        size="mini"
        type="primary"
      >
        开始推送
      </el-button>
      <!-- <el-button
        @click="canclePush()"
        class="goBackBtn"
        size="mini"
        type="primary"
      >
        取消推送
      </el-button> -->
    </div>
    <div v-else>
      <el-button @click="goBack()" class="goBackBtn" size="mini" type="primary">
        <i class="el-icon-back" />&nbsp;返回
      </el-button>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
// import bookApi from '@/api/book';
import { Message } from "element-ui";
import pushApi from "@/apis/pushApi";
export default {
  components: {
    pushApi,
  },
  filters: {
    formatDateToSecond: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    filterBadge(value) {
      switch (value) {
        case 1:
          return "无badge";
        case 2:
          return "有badge";
        default:
          return "";
      }
    },
    filterPush(value) {
      switch (value) {
        case 1:
          return "手动";
        case 2:
          return "自动";
        default:
          return "";
      }
    },
    filterRatio(value) {
      switch (value) {
        case 1:
          return "10%";
        case 2:
          return "20%";
        case 3:
          return "50%";
        case 4:
          return "100%";
        default:
          return "";
      }
    },
    filterTarget(value) {
      switch (value) {
        case "targetKind":
          return "目标类型";
        case "paintName":
          return "涂色分类名称";
        case "paintKey":
          return "涂色分类key";
        case "paintResourceKey":
          return "涂色资源key";
        case "themeName":
          return "涂色主题名称";
        case "themeKey":
          return "涂色模块/主题key｜涂色独立包主题推送";
        case "imageKey":
          return "涂色资源key";
        case "duid":
          return "duid";
        case "gcmId":
          return "gcmId";
        case "parameter1":
          return "参数1";
        case "parameter2":
          return "参数2";
        default:
          return "";
      }
    },
    filterValue(value) {
      switch (value) {
        case 70:
          return "涂色分类/图片推送";
        case 71:
          return "涂色模块/主题推送";
        case 72:
          return "涂色订阅商店推送";
        case 73:
          return "涂色IOS Daily推送";
        case 100:
          return "找不同 Daily推送";
        case 101:
          return "首页推送";
        case 102:
          return "活动推送";
        case 103:
          return "每日推送";
        case 105:
          return "免费Hint";
        case 106:
          return "ios静默推送";
        case 107:
          return "福利图片";
        case 108:
          return "情人节活动";
        case 109:
          return "Journey活动";
        case 110:
          return "涂色独立包主题推送";
        case 111:
          return "投票活动";
        case 112:
          return "涂色活动";
        case 113:
          return "ThemeKit分类 / 集合;";
        case 114:
          return "Pet";
        default:
          return value;
      }
    },
  },
  data() {
    return {
      userName: "",
      isApp: "",
      loading: false,
      appId: this.$route.params.appId || "",
      info: {},
      styleContent: "",
      pushStyle1: 1,
      pushStyle2: 2,
      pushStyle3: 3,
      pushStyle4: 4,
      pushStyle6: 6,
      pushRatio1: "",
      pushRatio2: "",
      pushRatio3: "",
      pushRatio4: "",
      pushRatio6: "",
      item: ["abc", "2", "4", "5"],
    };
  },
  async created() {
    this.userName = localStorage.getItem("username");
    this.loading = true;
    try {
      const id = this.$route.params.pushId;
      await this.getPushInfo(id);
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
  methods: {
    async getPushInfo(pushId) {
      const resp = await pushApi.getPushById({ pushId });
      this.info = resp.data;

      this.info = Object.assign({}, this.info);
      this.isApp = this.info.isApp;

      for (var i = 0; i < this.info.styleContents.length; i++) {
        if (this.info.styleContents[i].pushStyle == 1) {
          this.styleContent =
            this.pushStyle1 + ":" + this.info.styleContents[i].pushRatio + ",";
        }
        if (this.info.styleContents[i].pushStyle == 2) {
          this.styleContent =
            this.styleContent +
            this.pushStyle2 +
            ":" +
            this.info.styleContents[i].pushRatio +
            ",";
        }
        if (this.info.styleContents[i].pushStyle == 3) {
          this.styleContent =
            this.styleContent +
            this.pushStyle3 +
            ":" +
            this.info.styleContents[i].pushRatio +
            ",";
        }
        if (this.info.styleContents[i].pushStyle == 4) {
          this.styleContent =
            this.styleContent +
            this.pushStyle4 +
            ":" +
            this.info.styleContents[i].pushRatio;
        }
        if (this.info.styleContents[i].pushStyle == 6) {
          this.styleContent =
            this.styleContent +
            this.pushStyle6 +
            ":" +
            this.info.styleContents[i].pushRatio;
        }
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    forkTest(testType) {
      this.testPush(testType);
    },
    testPush(testType) {
      const pushId = this.$route.params.pushId;
      const type = testType;
      var testParame = {
        type: type,
        pushId: pushId,
      };
      const resp = pushApi
        .testPush(testParame)
        .then((response) => {
          if (response.status === 200) {
            Message({
              message: response.message,
              type: "success",
              duration: 5 * 1000,
            });
          }
        })
        .catch((response) => {
          Message({
            message: response.message,
            type: "error",
            duration: 5 * 1000,
          });
        });
    },
    directPush() {
      this.$confirm(
        "此做操不分时区不分国家‘当前时间’立即推送, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        const pushId = this.$route.params.pushId;
        const resp = pushApi
          .directPush({ pushId })
          .then((response) => {
            if (response.status === 0) {
              Message({
                message: response.message,
                type: "success",
                duration: 5 * 1000,
              });
            }
          })
          .catch((response) => {
            Message({
              message: response.message,
              type: "error",
              duration: 5 * 1000,
            });
          });
      });
    },
    canclePush() {
      const pushId = this.$route.params.pushId;
    },
    goToUpdate() {
      const pushId = this.$route.params.pushId;
      const isApp = this.info.isApp;
      this.$router.push(`/updatePush/${pushId}/${isApp}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.top {
  margin: 5% 15%;
  width: 60%;
}
.el-radio,
.checkbox,
.el-checkbox-group,
.trigger {
  float: left;
}

.selectInfo,
.pushInfo {
  margin: 5% 0;
}
.info-cantainer {
  //   padding: 40px 60px;
  .info-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    tr:nth-of-type(odd) {
      background-color: #f9f9f9;
    }
    tr > th {
      width: 30%;
      font-weight: 500;
    }
    tr > th,
    tr > td {
      border: 1px solid #ddd;
      padding: 8px 10px;
      text-align: left;
      font-size: 14px;
    }
    .tag-btn {
      color: #fff;
      font-size: 12px;
    }
    .flex-img {
      display: flex;
      align-items: center;
      .webp {
        margin-left: 20px;
        margin-right: 5px;
        font-weight: 500;
        font-size: 14px;
      }
      .img-div {
        width: 100px;
        height: 100px;
        position: relative;
        img {
          left: 50%;
          max-height: 100%;
          max-width: 100%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
