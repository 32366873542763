<template>
  <div>
    <div class="top">
      <!-- <el-button @click="showAddItem" size="medium" type="success">
        <i class="el-icon-plus" />&nbsp;创建新应用
      </el-button> -->
      <!-- <el-button @click="bulkCreate" size="medium" type="primary">
        <i class="el-icon-plus" />&nbsp;批量创建推送
      </el-button> -->
    </div>
    <div class="app-container">
      <el-table
        :data="list"
        @sort-change="handleSortChange"
        border
        element-loading-text="Loading"
        fit
        highlight-current-row
        row-class-name="table-row"
        v-loading="this.loading"
      >
        <el-table-column label="主题名称">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.name }}</template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="主题key">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.key }}</template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="banner图">
          <el-table-column>
            <template slot-scope="scope">
              <div
                :style="{ backgroundImage: `url(${scope.row.bannerImg})` }"
                class="previewImg"
              />
            </template>
          </el-table-column>
        </el-table-column>

        <el-table-column label="设计师">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.author }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column label="订阅人数" sortable="custom">
          <el-table-column>
            <template slot-scope="scope">{{ scope.row.num }}</template>
          </el-table-column>
        </el-table-column>
        <el-table-column header-align="center" label="操作">
          <el-table-column align="center" prop="created_at">
            <template slot-scope="scope">
              <el-button
                @click="showInfo(scope.row.key)"
                size="mini"
                type="primary"
                >预设推送信息</el-button
              >
              <el-button
                @click="showPushInfo(scope.row.key)"
                size="mini"
                type="view"
                >推送列表
              </el-button>
              <!-- <el-button
                @click="
                  handleSwitchStatus(
                    scope.row.commentId,
                    scope.row.replyId,
                    scope.row.status
                  )
                "
                size="mini"
                type="primary"
                >{{ scope.row.status | statusJudge }}
              </el-button> -->
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div>
        <el-pagination
          class="page"
          :current-page="AppInfo.pageNum"
          :page-size="AppInfo.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next"
          style="float: right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import AppApi from "@/apis/themeApi";
export default {
  components: { AppApi },
  data() {
    return {
      listLoading: true,
      // list: [
      //   {
      //     appId: 1,
      //     appName: "testApp",
      //     packageName: "testPackageName",
      //   },
      // ],
      loading: false,
      list: [],
      total: 1000,
      AppInfo: {
        pageSize: 10,
        pageNum: 1,
        order: "ASC",
        attribute: "id",
      },
      id: this.$route.params.id,
    };
  },
  filters: {
    formatData: function (value) {
      if (value == null || value == "") {
        return "";
      }
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d}");
    },
    formatDateToSecond: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    customOnlineAtHeader() {},

    fetchData() {
      AppApi.getList(this.AppInfo)
        .then((response) => {
          this.list = response.data.themeList;
          this.total = response.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    showAddItem() {
      const id = this.id;
      this.$router.push(`/updateApp/${id}`);
    },
    bulkCreate() {
      const appId = 1;
      this.$router.push(`/createPush/${appId}`);
    },
    showInfo(id) {
      this.$router.push(`/updateThemePush/${id}/3`);
    },
    showPushInfo(key) {
      var isApp = 1;
      this.$router.push(`/getTheme/${key}/${isApp}`);
    },
    handleSizeChange(val) {
      this.AppInfo.pageSize = val;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.AppInfo.pageNum = val;
      this.fetchData();
    },

    handleSortChange({ column, prop, order }) {
      const sortOrder = order === "ascending" ? "ASC" : "DESC";
      this.AppInfo.attribute = prop;
      this.AppInfo.order = sortOrder;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
  },
};
</script>
<style scoped>
.top {
  position: relative;
  float: left;
  margin: 1% 10%;
}
.app-container {
  margin: 0 5%;
  /* width: 70%; */
  visibility: none;
}
.page {
  /* width: 68%; */
  /* position: relative; */
  /* margin: 1% 5%; */
}

.previewImg {
  width: 50px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>