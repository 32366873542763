import request from '@/utils/request'



const appAPi = {
  getList: getList,
  createApp: createApp,
  updateApp: updateApp,
  getById: getById,
  uploadImg: uploadImg
}
export function getList(params) {
  return request({
    url: '/app/getAppList',
    method: 'get',
    params
  })
}
export function getById(params) {
  return request({
    url: '/app/getAppById',
    method: 'get',
    params
  })
}
export function createApp(params) {
  return request({
    url: '/app/createOrUpdate',
    method: 'post',
    data: params
    
  })
}

export function updateApp(params) {
  return request({
    url: '/app/createOrUpdate',
    method: 'post',
    data: params
  })
}

export function uploadImg(params) {
  return request({
    url: '/app/uploadImg',
    method: 'post',
    data: params
  })
}

export default appAPi
