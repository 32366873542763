<template>
  <div>
    <div class="top">
      <el-button
        v-if="appId"
        @click="showAddItem(appId)"
        size="medium"
        type="success"
      >
        <i class="el-icon-plus" />&nbsp;添加推送通知
      </el-button>
      <el-button
        v-else
        @click="addThemeItem(themeKey)"
        size="medium"
        type="success"
      >
        <i class="el-icon-plus" />&nbsp;添加推送主题
      </el-button>
    </div>
    <div class="app-container">
      <el-table
        :data="list"
        @sort-change="handleSortChange"
        border
        element-loading-text="Loading"
        fit
        highlight-current-row
        row-class-name="table-row"
        v-loading="this.loading"
      >
        <el-table-column label="推送ID">
          <template slot-scope="scope">{{ scope.row.id }}</template>
        </el-table-column>

        <el-table-column label="推送名称">
          <template slot-scope="scope">{{ scope.row.pushName }}</template>
        </el-table-column>
        <!-- <el-table-column label="推送类型">
          <template slot-scope="scope">{{
            scope.row.notifyType | filterNotify
          }}</template>
        </el-table-column> -->
        <el-table-column v-if="AppInfo.isApp == 0" label="推送应用">
          <template slot-scope="scope">{{ scope.row.appName }} </template>
        </el-table-column>
        <el-table-column v-if="AppInfo.isApp == 1" label="推送主题">
          <template slot-scope="scope">{{ scope.row.themeName }} </template>
        </el-table-column>
        <el-table-column label="推送方式（手动、自动）">
          <template slot-scope="scope">{{
            scope.row.pushType | filterPush
          }}</template>
        </el-table-column>
        <el-table-column label="推送人数">
          <template slot-scope="scope">{{ scope.row.pushPeople }}</template>
        </el-table-column>
        <el-table-column
          label="自动推送时间"
          prop="auto_push_time"
          sortable="custom"
        >
          <template slot-scope="scope">{{
            scope.row.autoPushTime | formatDateToSecond
          }}</template>
        </el-table-column>

        <el-table-column label="状态">
          <template slot-scope="scope">{{
            scope.row.pushStatus | filterStatus
          }}</template>
        </el-table-column>
        <!-- <el-table-column label="推送种类">
          <template slot-scope="scope">{{
            scope.row.pushKind | filterPushKind
          }}</template>
        </el-table-column> -->
        <el-table-column header-align="center" label="操作" prop="created_at">
          <template slot-scope="scope">
            <el-button
              @click="showInfo(scope.row.id, scope.row.key)"
              size="mini"
              type="primary"
              >查看</el-button
            >
            <el-button
              v-if="appId"
              @click="copy(scope.row.id)"
              size="mini"
              type="primary"
              >复制
            </el-button>
            <el-button
              v-if="scope.row.pushType != 2 || userName == 'natasha.liao'"
              @click="deletePush(scope.row.id)"
              style="float: left; margin: 3% 1% 1% 0"
              size="mini"
              type="danger"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div>
        <el-pagination
          class="page"
          :current-page="AppInfo.pageNum"
          :page-size="AppInfo.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next"
          style="float: right"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import pushApi from "@/apis/pushApi";
import appApi from "@/apis/appApi";
import { Message, MessageBox } from "element-ui";
export default {
  components: {
    pushApi,
    appApi,
  },
  inject: ["reload"],
  data() {
    return {
      listLoading: true,
      loading: true,
      list: [],
      appList: [],
      userName: "",
      listInfo: {
        pageSize: 999999,
        pageNum: 1,
        order: "DESC",
        attribute: "create_at",
      },
      titleSearchFlag: -1,
      pushIdSearchFlag: -1,
      updateAtSearchFlag: -1,
      total: 1000,
      value: "",
      AppInfo: {
        pageSize: 10,
        pageNum: 1,
        pushName: "",
        pushId: "",
        notifyType: "",
        pushKind: "",
        pushStatus: "",
        order: "DESC",
        attribute: "create_at",
        updateAt: "",
        appId: this.$route.params.appId || "",
        isApp: this.$route.params.isApp || 1,
        themeKey: this.$route.params.key || "",
        // appId: 3,
      },
      options: [],
      appName: this.$route.params.appName || "",
      appId: this.$route.params.appId,
      themeKey: this.$route.params.key,
    };
  },
  created() {
    this.userName = localStorage.getItem("username");
    this.fetchData();
    this.getAppList();
    // this.getThemeList()
    console.log("appname" + this.appName);
  },

  filters: {
    formatData: function (value) {
      if (value == null || value == "") {
        return "";
      }
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d}");
    },
    formatDateToSecond: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    filterPush(value) {
      switch (value) {
        case 1:
          return "手动";
        case 2:
          return "自动";
        default:
          return "";
      }
    },
    filterRatio(value) {
      switch (value) {
        case 1:
          return "10%";
        case 2:
          return "20%";
        case 3:
          return "50%";
        case 4:
          return "100%";
        default:
          return "";
      }
    },
    filterNotify(value) {
      switch (value) {
        case 1:
          return "无";
        case 2:
          return "通知类型－自定义样式";
        case 3:
          return "通知类型－单张图片样";
        default:
          return "全部";
      }
    },
    filterStatus(value) {
      switch (value) {
        case 0:
          return "创建完成";
        // case 1:
        //   return "";
        case 50:
          return "推送中";
        case 60:
          return "推送完成";
        case 61:
          return "推送失败";
        default:
          return "推送中";
      }
    },
    filterPushKind(value) {
      switch (value) {
        case 1:
          return "测试推送";
        case 2:
          return "普通推送";
        case 3:
          return "分国家推送";
        case 4:
          return "分语言推送";

        default:
          return "全部";
      }
    },
  },
  methods: {
    deletePush(id) {
      MessageBox.confirm("确定删除该条推送?", "提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() =>
        pushApi
          .deletePush({ pushId: id })
          .then((response) => {
            if (response.status == 200) {
              this.fetchData();
            }
          })
          .catch((res) => {
            console.log(res);
          })
      );
    },
    getAppList() {
      appApi
        .getList(this.listInfo)
        .then((response) => {
          var list = [{ appId: null, appName: "全部" }];
          this.appList = Array.from(list.concat(response.data));
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    copy(pushId) {
      console.log(pushId);
      var flag = true;
      this.$router.push(`/copyPushInfo/${pushId}/${flag}`);
    },
    fetchData() {
      const app = {
        appId: this.appId,
      };
      // this.AppInfo.outPush = app;
      // console.log("====" + this.AppInfo.outPush.appId);
      pushApi
        .getList(this.AppInfo)
        .then((response) => {
          this.list = response.data.pushList;
          this.total = response.data.total;
          this.loading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    update(pushId) {
      this.$router.push(`/updatePush/${pushId}`);
    },
    showInfo(pushId) {
      this.$router.push(`/getPushInfo/${pushId}`);
    },
    reloadDate() {
      if (this.$route.params.appName == "undefined") {
        this.reload();
      }
      return;
    },
    showAddItem(appId) {
      this.$router.push(`/createPush/${appId}/0`);
    },
    addThemeItem(themeKey) {
      this.$router.push(`/createPush/${themeKey}/1`);
    },
    handleSizeChange(val) {
      this.AppInfo.pageSize = val;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.AppInfo.pageNum = val;
      this.fetchData();
    },

    handleSortChange({ column, prop, order }) {
      const sortOrder = order === "ascending" ? "ASC" : "DESC";
      this.AppInfo.attribute = prop;
      this.AppInfo.order = sortOrder;
      this.AppInfo.pageNum = 1;
      this.fetchData();
    },
    customRenderCategoryHeader() {},
    customOnlineAtHeader() {},
    customUpdateAt(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.updateAt,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.updateAt = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.updateAtSearchFlag);
            $vm.pushIdSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customApp(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.appId,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.appId = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          this.appList.map((item) => {
            console.log("===>" + item.appName);
            return h("el-option", {
              props: {
                value: item.appId,
                label: item.appName,
                placeholder: item.appName,
              },
            });
          }),
        ]
      );
    },
    customNotifyType(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.appName,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.notifyType = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "无",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "通知类型－单张图片样式",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "通知类型－自定义样式",
            },
          }),
        ]
      );
    },
    customPushStatus(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.pushStatus,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.pushStatus = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "推送中",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "推送完成",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "推送失败",
            },
          }),
        ]
      );
    },
    customPushKind(h) {
      const $vm = this;
      return h(
        "el-select",
        {
          props: {
            value: $vm.AppInfo.pushKind,
            size: "mini",
          },
          on: {
            change: (value) => {
              console.log("value" + value);
              $vm.AppInfo.pushKind = value;
              $vm.AppInfo.pageNum = 1;
              $vm.fetchData();
            },
          },
        },
        [
          h("el-option", {
            props: {
              value: "",
              label: "全部",
            },
          }),
          h("el-option", {
            props: {
              value: 1,
              label: "测试推送",
            },
          }),
          h("el-option", {
            props: {
              value: 2,
              label: "普通推送",
            },
          }),
          h("el-option", {
            props: {
              value: 3,
              label: "分国家推送",
            },
          }),
          h("el-option", {
            props: {
              value: 4,
              label: "分语言推送",
            },
          }),
        ]
      );
    },
    customPushId(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.pushId,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.pushId = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.pushIdSearchFlag);
            $vm.pushIdSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
    customRenderTitleHeader(h) {
      const $vm = this;
      return h("el-input", {
        props: {
          value: $vm.AppInfo.pushName,
          size: "mini",
        },
        on: {
          input: (value) => {
            console.log("value" + value);
            $vm.AppInfo.pushName = value;
            $vm.AppInfo.pageNum = 1;
            clearTimeout($vm.titleSearchFlag);
            $vm.titleSearchFlag = setTimeout(() => {
              $vm.fetchData();
            }, 500);
          },
        },
      });
    },
  },
};
</script>
<style scoped>
.top {
  position: relative;
  float: left;
  margin: 1% 10%;
}
.app-container {
  margin-top: 2%;
  margin-left: 10%;
  /* border: 0px solid black; */
  width: 70%;
  visibility: none;
}
.page {
  width: 68%;
  position: relative;
  /* margin: 1% 5%; */
}
</style>