<template>
  <div class="top">
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送基本信息</span>
        <el-form ref="form" :model="form" label-width="100px" size="mini">
          <el-form-item label="推送应用" v-if="appId == 1 || flag">
            <el-checkbox
              class="checkbox"
              :indeterminate="isAppIndeterminate"
              v-model="checkAppAll"
              @change="handleCheckAppAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="checkAppGroup"
              @change="handleCheckedAppGroupChange"
              style="float: left"
            >
              <el-checkbox
                v-for="app in appGroup"
                :label="app"
                :key="app"
                style="float: left; margin: 15px 0"
              >
                <div
                  :style="{
                    color: colorList[changeColor(app)],
                  }"
                  class="appGroup"
                >
                  {{ app }}
                </div>

                <div v-if="app == 'Dark Group'">
                  <el-checkbox-group
                    v-model="form.checkedApps"
                    @change="handleCheckedAppChange"
                  >
                    <el-checkbox
                      style="float: left; line-height: 30px"
                      v-for="appName in darkList"
                      :label="appName"
                      :key="appName"
                    >
                      {{ appName }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-if="app == 'Common Group'">
                  <el-checkbox-group
                    v-model="form.checkedApps"
                    @change="handleCheckedAppChange"
                  >
                    <el-checkbox
                      style="float: left; line-height: 30px"
                      v-for="appName in commonList"
                      :label="appName"
                      :key="appName"
                    >
                      {{ appName }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-if="app == 'Black Group'">
                  <el-checkbox-group
                    v-model="form.checkedApps"
                    @change="handleCheckedAppChange"
                  >
                    <el-checkbox
                      style="float: left; line-height: 30px"
                      v-for="appName in blackList"
                      :label="appName"
                      :key="appName"
                    >
                      {{ appName }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-if="app == 'Sexy Group'">
                  <el-checkbox-group
                    v-model="form.checkedApps"
                    @change="handleCheckedAppChange"
                  >
                    <el-checkbox
                      style="float: left; line-height: 30px"
                      v-for="appName in sexyList"
                      :label="appName"
                      :key="appName"
                    >
                      {{ appName }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
                <div v-if="app == 'No Group'">
                  <el-checkbox-group
                    v-model="form.checkedApps"
                    @change="handleCheckedAppChange"
                  >
                    <el-checkbox
                      style="float: left; line-height: 30px"
                      v-for="appName in noList"
                      :label="appName"
                      :key="appName"
                    >
                      {{ appName }}
                    </el-checkbox>
                  </el-checkbox-group>
                </div>
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="推送名称">
            <el-input v-model="form.pushName"></el-input>
          </el-form-item>
          <!-- <el-form-item label="正式推送">
            <el-radio v-model="form.pushKind" :label="labelNum1"
              >测试推送</el-radio
            >
            <el-radio v-model="form.pushKind" :label="labelNum2"
              >普通推送</el-radio
            >
            <el-radio v-model="form.pushKind" :label="labelNum3"
              >分国家推送</el-radio
            >
            <el-radio v-model="form.pushKind" :label="labelNum4"
              >分语言推送</el-radio
            >
          </el-form-item> -->
          <!-- <el-form-item label="预装通知">
            <el-radio v-model="radio" :label="labelNum1">是</el-radio>
            <el-radio v-model="radio" :label="labelNum2">否</el-radio>
          </el-form-item> -->
          <div v-if="isApp == 2">
            <el-form-item label="标题">
              <el-button
                style="float: left"
                icon="el-icon-plus"
                @click="addTitles()"
                type="primary"
                size="mini"
                >增加</el-button
              >
            </el-form-item>
            <div v-for="item in form.titles" :key="item.index" label="推送标题">
              <el-form-item label="推送标题">
                <!-- <el-input
                v-if="form.pushTitles.length == 0"
                v-model="form.pushTitle"
              ></el-input> -->
                <el-form-item
                  v-for="items in item.pushTitles"
                  :label="items.name"
                  :key="items.index"
                >
                  <el-input type="textarea" v-model="items.value"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item label="推送副标题">
                <el-form-item
                  v-for="city in item.pushSubtitles"
                  :label="city.name"
                  :key="city.index"
                >
                  <el-input type="textarea" v-model="city.value"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item>
                <el-col :span="2"
                  ><el-button
                    style="margin: 0 0 0 130%"
                    icon="el-icon-delete"
                    type="danger"
                    @click="delTitles(item.index)"
                    >删除主/副标题</el-button
                  ></el-col
                >
              </el-form-item>
            </div>
          </div>
          <div v-else>
            <el-form-item label="推送标题">
              <el-input
                v-if="form.pushTitles.length == 0"
                v-model="form.pushTitle"
              ></el-input>
              <el-form-item
                v-for="item in form.pushTitles"
                :label="item.name"
                :key="item.index"
              >
                <el-input type="textarea" v-model="item.value"></el-input>
              </el-form-item>
            </el-form-item>
            <el-form-item label="推送副标题">
              <el-input
                type="textarea"
                v-if="form.pushSubtitles.length == 0"
                v-model="form.pushSubtitle"
              ></el-input>
              <el-form-item
                v-for="city in form.pushSubtitles"
                :label="city.name"
                :key="city.index"
              >
                <el-input type="textarea" v-model="city.value"></el-input>
              </el-form-item>
            </el-form-item>
          </div>

          <el-form-item label="推送方式(手动、自动)">
            <el-radio v-model="form.pushType" :label="labelNum1"
              >手动推送</el-radio
            >
            <el-radio
              v-if="userName != 'honglin.Gan'"
              v-model="form.pushType"
              :label="labelNum2"
              @click="setNowTime()"
              >自动推送(分国家定时自动推送推送不用填写自动推送时间)</el-radio
            >
          </el-form-item>
          <el-form-item v-if="form.pushType == 2" label="自动推送时间">
            <el-col :span="11">
              <el-date-picker
                v-model="data2"
                type="datetime"
                placeholder="选择日期时间"
                default-time="09:00:00"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="推送类型">
            <el-radio v-model="form.notifyType" :label="labelNum1">无</el-radio>
            <el-radio v-model="form.notifyType" :label="labelNum2"
              >通知类型-单张图片</el-radio
            >
            <el-radio v-model="form.notifyType" :label="labelNum3"
              >通知类型-自定义样式</el-radio
            >
          </el-form-item>
          <el-form-item label="数据格式(badge)">
            <el-radio v-model="form.badge" :label="labelNum1">无badge</el-radio>
            <el-radio v-model="form.badge" :label="labelNum2">有badge</el-radio>
          </el-form-item>
          <!-- <el-form-item label="铃声提示">
            <el-radio v-model="radio" :label="labelNum1">是</el-radio>
            <el-radio v-model="radio" :label="labelNum2">否</el-radio>
          </el-form-item>
          <el-form-item label="震动提示">
            <el-radio v-model="radio" :label="labelNum1">是</el-radio>
            <el-radio v-model="radio" :label="labelNum2">否</el-radio>
          </el-form-item> -->
          <!-- <el-form-item label="手机本地开始时间">
            <el-col :span="11">
              <el-date-picker
                v-model="data"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-col>
          </el-form-item> -->
          <!-- <el-form-item label="手机本地结束时间">
            <el-col :span="11">
              <el-date-picker
                v-model="data1"
                type="datetime"
                placeholder="选择日期时间"
              >
              </el-date-picker>
            </el-col>
          </el-form-item> -->
          <el-form-item label="推送样式">
            <el-checkbox
              v-if="isApp != 2"
              style="width: 80px; float: left"
              v-model="checked1"
              >样式1</el-checkbox
            >
            <el-checkbox
              v-if="isApp != 2"
              style="width: 80px; float: left"
              v-model="checked2"
              >样式2</el-checkbox
            >
            <el-checkbox style="width: 80px; float: left" v-model="checked3"
              >样式3</el-checkbox
            >
            <el-checkbox style="width: 80px; float: left" v-model="checked4"
              >样式4</el-checkbox
            >
            <el-checkbox
              v-if="isApp != 2"
              style="width: 80px; float: left"
              v-model="checked6"
              >样式6(ios)</el-checkbox
            >
            <el-checkbox style="width: 80px; float: left" v-model="checked5"
              >样式5(android自动)</el-checkbox
            >
          </el-form-item>
          <el-form-item label="推送比例">
            <el-form-item v-if="checked1" label="样式1比例">
              <el-input
                style="width: 80px; float: left"
                value="123"
                v-model="pushRatio1"
                placeholder="例如:50"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio1 = $event.target.value"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="checked2" label="样式2比例">
              <el-input
                style="width: 80px; float: left"
                placeholder="例如:50"
                v-model="pushRatio2"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio3 = $event.target.value"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="checked3" label="样式3比例">
              <el-input
                style="width: 80px; float: left"
                placeholder="例如:50"
                v-model="pushRatio3"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio3 = $event.target.value"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="checked4" label="样式4比例">
              <el-input
                style="width: 80px; float: left"
                placeholder="例如:50"
                v-model="pushRatio4"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio4 = $event.target.value"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="checked5" label="样式5比例">
              <el-input
                style="width: 80px; float: left"
                placeholder="例如:50"
                v-model="pushRatio5"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio5 = $event.target.value"
              ></el-input>
            </el-form-item>
            <el-form-item v-if="checked6" label="样式6比例">
              <el-input
                style="width: 80px; float: left"
                placeholder="例如:50"
                v-model="pushRatio6"
                onkeyup="value=value.replace(/\D/g,'')"
                onafterpaste="value=value.replace(/\D/g,'')"
                @blur="pushRatio6 = $event.target.value"
              ></el-input>
            </el-form-item>
          </el-form-item>
          <el-form-item
            v-if="checked2 || checked1"
            :label-width="formLabelWidth"
            label="icon(120*120)"
            prop="icon"
          >
            <el-upload
              drag
              style="float: left"
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadIconImage"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img v-if="form.pushIcon" :src="form.pushIcon" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="checked1"
            :label-width="formLabelWidth"
            label="banner(720*360)"
            prop="banner"
          >
            <el-upload
              style="float: left"
              drag
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadBannerImage"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pushBanner"
                :src="form.pushBanner"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="checked2"
            :label-width="formLabelWidth"
            label="banner2(720*360)"
            prop="banner2"
          >
            <el-upload
              drag
              style="float: left"
              v-if="checked2"
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadBannerImage2"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pushBanner2"
                :src="form.pushBanner2"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="checked3 && isApp != 2"
            :label-width="formLabelWidth"
            label="banner3(720*128,标题左)"
            prop="banner3"
          >
            <el-upload
              drag
              style="float: left"
              v-if="checked3"
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadBannerImage3"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pushBanner3"
                :src="form.pushBanner3"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item
            v-if="checked4 && isApp != 2"
            :label-width="formLabelWidth"
            label="banner4(720*128,标题中)"
            prop="banner3"
          >
            <el-upload
              drag
              style="float: left"
              v-if="checked4"
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadBannerImage4"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pushBanner4"
                :src="form.pushBanner4"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>

          <el-form-item
            v-if="checked6"
            :label-width="formLabelWidth"
            label="banner6"
            prop="banner"
          >
            <el-upload
              drag
              style="float: left"
              v-if="checked6"
              :width="130"
              :height="130"
              class="avatar-uploader"
              action="21"
              :http-request="uploadBannerImage6"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.pushBanner6"
                :src="form.pushBanner6"
                class="avatar"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <!-- <el-form-item size="large">
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item> -->
          <div v-if="checked5 || checked3 || checked4">
            <el-form-item label="添加资源">
              <el-button
                style="float: left"
                icon="el-icon-plus"
                @click="addBanner()"
                type="primary"
                size="mini"
                >增加</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-row v-for="item in banners" :key="item.index">
                <el-form-item label="banner图片:">
                  <el-upload
                    drag
                    style="float: left"
                    :width="130"
                    :height="130"
                    class="avatar-uploader"
                    action="21"
                    :http-request="
                      (file) => {
                        return uploadBannerImage5(file, banners.indexOf(item));
                      }
                    "
                    :show-file-list="false"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                  >
                    <img v-if="item.banner" :src="item.banner" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </el-form-item>
                <el-form-item>
                  <el-col :span="2"
                    ><el-button
                      style="margin: 0 0 0 130%"
                      icon="el-icon-delete"
                      type="danger"
                      @click="delDays(item.index)"
                      >删除Banner</el-button
                    ></el-col
                  >
                </el-form-item>
              </el-row>
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="selectInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 根据条件筛选用户</span>
        <el-form label-width="100px" size="mini">
          <!-- <el-form-item class="effect" label="影响人数:">
            <span> {{ this.form.pushPeople }}</span>
            <el-button size="mini" type="primary"> 刷新 </el-button>
          </el-form-item> -->
          <el-form-item label="国家">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkCountryAll"
              @change="handleCheckAllChange"
              class="checkbox"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="form.checkedCities"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                city
              }}</el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <el-form-item label="语言">
            <el-checkbox
              class="checkbox"
              :indeterminate="isLanguageIndeterminate"
              v-model="checkLanguageAll"
              @change="handleCheckLanguageAllChange"
              >全选</el-checkbox
            >
            <div style="margin: 15px 0"></div>
            <el-checkbox-group
              v-model="form.checkedLanguages"
              @change="handleCheckedLanguageChange"
            >
              <el-checkbox
                v-for="language in languages"
                :label="language"
                :key="language"
                >{{ language }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="推送比例">
            <el-radio v-model="form.pushRatio" :label="labelNum1">10%</el-radio>
            <el-radio v-model="form.pushRatio" :label="labelNum2">25%</el-radio>
            <el-radio v-model="form.pushRatio" :label="labelNum3"
              >50%
            </el-radio>
            <el-radio v-model="form.pushRatio" :label="labelNum4"
              >100%</el-radio
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送触发条件</span>
        <el-form ref="form" :model="form" label-width="100px" size="mini">
          <el-form-item label="触发条件">
            <el-radio v-model="touch" :label="labelNum1">键盘弹起</el-radio>
            <el-radio v-model="touch" :label="labelNum2"
              >unlock screen</el-radio
            >
            <el-radio v-model="touch" :label="labelNum3">主动收起键盘</el-radio>
            <el-radio v-model="touch" :label="labelNum4">立即触发</el-radio>
          </el-form-item>
          <el-form-item label="键盘激活">
            <el-radio v-model="keyBoard" :label="labelNum1">激活</el-radio>
            <el-radio v-model="keyBoard" :label="labelNum2">未激活</el-radio>
            <el-radio v-model="keyBoard" :label="labelNum3">全集</el-radio>
          </el-form-item>
          <el-form-item label="网络状态">
            <el-radio v-model="network" :label="labelNum1">Wifi</el-radio>
            <el-radio v-model="network" :label="labelNum2">Mobile</el-radio>
            <el-radio v-model="network" :label="labelNum3"
              >Wifi or Mobile</el-radio
            >
            <el-radio v-model="network" :label="labelNum4"
              >no network limit</el-radio
            >
          </el-form-item>
          <el-form-item label="强制推送">
            <el-radio v-model="isPush" :label="labelNum1">是</el-radio>
            <el-radio v-model="isPush" :label="labelNum2">否</el-radio>
          </el-form-item>
          <el-form-item label="触发延迟">
            <el-select
              class="trigger"
              v-model="value"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <el-tabs class="pushInfo" type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="el-icon-date"></i> 推送目标信息</span>
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="目标类型">
            <el-radio v-model="form.targetKind" :label="target6"
              >首页推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target7"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >活动推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              :label="target1"
              >涂色分类/图片推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target12"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >福利图推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target2"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >涂色模块/主题推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target2"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_android') != -1 ||
                role.indexOf('intern') != -1 ||
                role.indexOf('test') != -1
              "
              >分类/资源推送(themeKit)</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target15"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >独立包主题推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target3"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >涂色订阅商店推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target4"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_android') != -1 ||
                role.indexOf('test') != -1
              "
              >涂色IOS Daily推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target5"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_android') != -1 ||
                role.indexOf('test') != -1
              "
              >找不同 Daily推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target9"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_ios') != -1 ||
                role.indexOf('test') != -1
              "
              >ThemeKitIOS Preview推送</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target8"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >每日上新</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target10"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >免费Hint</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target19"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_android') != -1 ||
                role.indexOf('themekit_ios') != -1 ||
                role.indexOf('intern') != -1 ||
                role.indexOf('test') != -1
              "
              >宠物Pet</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target20"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >徽章</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target11"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_ios') != -1 ||
                role.indexOf('themekit_android') != -1 ||
                role.indexOf('test') != -1
              "
              >ios静默推送</el-radio
            >
            <!-- <el-radio v-model="form.targetKind" :label="target13"
              >情人节活动</el-radio
            >
            <el-radio v-model="form.targetKind" :label="target14"
              >Journey活动</el-radio
            > -->
            <el-radio
              v-model="form.targetKind"
              :label="target17"
              @change="changable()"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >涂色活动</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target22"
              @change="changable()"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1
              "
              >News活动</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target21"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('paint') != -1 ||
                role.indexOf('test') != -1 ||
                role.indexOf('intern') != -1
              "
              >Daily-Android</el-radio
            >
            <el-radio
              v-model="form.targetKind"
              :label="target18"
              v-if="
                role.indexOf('admin') != -1 ||
                role.indexOf('themekit_ios') != -1 ||
                role.indexOf('intern') != -1 ||
                role.indexOf('test') != -1
              "
              >ThemeKit分类/集合</el-radio
            >
          </el-form-item>
          <!-- <el-form-item v-if="form.targetKind == 70" label="涂色分类名称">
            <el-select
              style="float: left; width: 300px"
              @click.native="getCategory('')"
              v-model="form.paintName"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in categoryKeys"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
          <!-- <el-input v-model="form.paintName"></el-input> -->
          <!-- </el-form-item> -->
          <el-form-item v-if="form.targetKind == 70" label="涂色分类key">
            <el-input v-model="form.paintKey"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 70 || form.targetKind == 107"
            label="涂色资源key"
          >
            <el-input v-model="form.paintResourceKey"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 71 && isApp == 0"
            label="涂色主题名称"
          >
            <el-input v-model="form.themeName"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 71 && isApp == 0"
            label="涂色模块/主题key"
          >
            <el-input
              v-model="form.themeKey"
              placeholder="格式为:模块key@@主题key"
            ></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 110 && isApp == 0"
            label="涂色独立包主题key"
          >
            <el-input
              v-model="form.themeKey"
              placeholder="格式为:主题key"
            ></el-input>
          </el-form-item>
          <el-form-item v-if="form.targetKind == 73" label="涂色资源key">
            <el-input v-model="form.imageKey"></el-input>
          </el-form-item>
          <el-form-item v-if="form.targetKind == 113" label="类型">
            <el-select
              style="float: left; width: 300px"
              v-model="form.parameter2"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in themeKitList"
                :key="item.value"
                :label="item.lable"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="
              form.targetKind == 102 ||
              form.targetKind == 104 ||
              form.targetKind == 113
            "
            label="key"
          >
            <el-input
              v-model="form.parameter1"
              placeholder="分类key/资源key"
            ></el-input>
          </el-form-item>

          <el-form-item v-if="form.targetKind == 102" label="参数2">
            <el-input v-model="form.parameter2"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 103 || form.targetKind == 105"
            label="涂色分类key"
          >
            <el-input v-model="form.paintKey"></el-input>
          </el-form-item>
          <el-form-item v-if="form.targetKind == 115" label="徽章key">
            <el-input v-model="form.parameter1"></el-input>
          </el-form-item>
          <el-form-item
            v-if="form.targetKind == 112 || form.targetKind == 117"
            :label="lableName"
          >
            <el-select
              style="float: left; width: 300px"
              @click.native="getActivity('')"
              v-model="form.themeKey"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in activityList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <!-- <el-input v-model="form.paintName"></el-input> -->
          </el-form-item>
          <el-form-item label="duid">
            <el-input v-model="form.duid"></el-input>
          </el-form-item>
          <el-form-item label="gcm ID">
            <el-input v-model="form.gcmId"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <el-button
      v-if="isApp1 != 4"
      @click="savePush(0)"
      class="goBackBtn"
      size="mini"
      type="primary"
    >
      保存
    </el-button>
    <el-button @click="testPush(1)" class="goBackBtn" size="mini" type="primary"
      >手动测试
    </el-button>
  </div>
</template>

<script>
import { parseTime } from "@/utils";
import { Message, MessageBox } from "element-ui";
import pushApi from "@/apis/pushApi";
import Upload from "@/components/upload/index";
import axios from "axios";
import appApi from "@/apis/appApi";
export default {
  components: {
    pushApi,
    Upload,
    appApi,
  },
  data() {
    return {
      userName: "",
      isApp1: "",
      banners: [],
      newList: [],
      darkList: [
        "Tattoo Color",
        "Joker Color",
        "Skull Color",
        "Dark Color",
        "Death Color",
        "Fantasy Color",
        "Skeleton Color",
      ],
      blackList: [
        "Paint Color",
        "Coloring Games",
        "Fun Color",
        "Black Color",
        "Silver Color",
        "Farm Color",
        "Black Color 2.0"
      ],
      sexyList: [
        "Sports Color",
        "Sexy Color",
        "Car Color",
        "Anime Color",
        "Summer Color",
        "Color Events",
      ],
      commonList: [
        "Animal Color",
        "Dog Color",
        "Festival Color",
        "Street Color",
        "Rose Color",
        "Betty Color",
        "Retro Color",
        "West Color",
        "Love Valentine's Color",
        "Quote Color",
        "Movie Color",
      ],
      lableName: "涂色活动Key",
      noList: [],
      activityList: [],
      checkAppGroup: [],
      flag: this.$route.params.flag || false,
      radio: 2,
      formLabelWidth: "120px",
      data: 0,
      data1: 0,
      data2: "",
      data3: 0,
      labelNum1: 1,
      labelNum2: 2,
      labelNum3: 3,
      labelNum4: 4,

      target1: 70,
      target2: 71,
      target3: 72,
      target4: 73,
      target5: 100,
      target6: 101,
      target7: 102,
      target8: 103,
      target9: 104,
      target10: 105,
      target11: 106,
      target12: 107,
      target13: 108,
      target14: 109,
      target15: 110,
      target16: 111,
      target17: 112,
      target18: 113,
      target19: 114,
      target20: 115,
      target21: 116,
      target22: 117,
      pushStyle1: 1,
      pushStyle2: 2,
      pushStyle3: 3,
      pushStyle4: 4,
      pushStyle5: 5,
      pushStyle6: 6,
      pushRatio1: "",
      pushRatio2: "",
      pushRatio3: "",
      pushRatio4: "",
      pushRatio5: 100,
      pushRatio6: "",
      checked1: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false,
      checked6: false,
      form: {
        delivery: false,
        attribute: "",
        desc: "",
        pushName: "",
        pushTitle: "",
        pushSubtitle: "",
        pushType: 0,
        notifyType: 1,
        badge: 2,
        pushKind: 4,
        targetKind: 0,
        paintName: "",
        paintKey: "",
        paintResourceKey: "",
        themeName: "",
        themeKey: "",
        isApp: 0,
        imageKey: "",
        duid: "",
        gcmId: "",
        parameter1: "",
        parameter2: "",
        pushPeople: 0,
        pushRatio: 4,
        pushIcon: "",
        pushBanner: "",
        pushBanner2: "",
        pushBanner3: "",
        pushBanner4: "",
        pushBanner5: "",
        pushBanner6: "",
        checkedCities: [],
        coloringThemeKey: "",
        checkedLanguages: ["英文"],
        checkedApps: [],
        titles: [],
        subTitles: [],
        pushSubtitles: [
          {
            name: "英文",
            value: "",
          },
        ],
        styleContent: [],
        pushTitles: [
          {
            name: "英文",
            value: "",
          },
        ],
      },
      colorList: [
        "#337ab7",
        "#5cb85c",
        "#777",
        "#5bc0de",
        "#d9534f",
        "#f0ad4e",
        "#795548",
        "#8c6ec1",
        "#756b6b",
        "#cad280",
      ],
      themeKitList: [
        {
          lable: "Theme页",
          value: "0",
        },
        {
          lable: "Wallpaper页",
          value: "1",
        },
        {
          lable: "Home Screen页",
          value: "2",
        },
        {
          lable: "Lock Screen页",
          value: "3",
        },
        {
          lable: "Discover页",
          value: "4",
        },
      ],
      last: [],
      role: [],
      paintGroupFlag: false,
      appId: this.$route.params.appId || "",
      themeKey: this.$route.params.appId || "",
      key: this.$route.params.appId || "",
      isApp: this.$route.params.isApp || 0,
      pushId: this.$route.params.pushId,
      pushAppId: "",
      isPush: 2,
      icon: "",
      value: "0s",
      touch: 4,
      network: 4,
      keyBoard: 3,
      peopleNum: 100,
      checkLanguageAll: false,
      checkAppAll: false,
      checkCountryAll: false,
      isIndeterminate: false,
      isAppIndeterminate: true,
      isLanguageIndeterminate: true,
      options: [
        {
          value: "0s",
          label: "0秒",
        },
        {
          value: "10s",
          label: "10秒",
        },
        {
          value: "20s",
          label: "20秒",
        },
        {
          value: "30s",
          label: "30秒",
        },
        {
          value: "60s",
          label: "60秒",
        },
        {
          value: "120s",
          label: "120秒",
        },
        {
          value: "300s",
          label: "300秒",
        },
      ],
      appList: [],
      appGroup: [
        "Dark Group",
        "Black Group",
        "Common Group",
        "Sexy Group",
        "No Group",
      ],
      cities: [
        "中国",
        "美国",
        "巴西",
        "法国",
        "墨西哥",
        "印尼",
        "俄国",
        "土耳其",
        "西班牙",
        "菲律宾",
        "印度",
        "阿根廷",
        "哥伦比亚",
        "智利",
        "意大利",
        "德国",
        "英国",
        "波兰",
        "马来西亚",
        "荷兰",
        "比利时",
        "葡萄牙",
        "加拿大",
        "泰国",
        "澳大利亚",
        "秘鲁",
        "新加坡",
        "罗马尼亚",
        "伊朗",
        "埃及",
        "委内瑞拉",
        "厄瓜多尔",
        "南非",
        "伊拉克共和国",
        "多米尼加",
        "阿尔及利亚日",
        "危地马拉",
        "玻利维亚",
        "沙特阿拉伯",
        "乌克兰",
        "捷克",
        "巴拉圭",
        "萨尔瓦多",
        "越南",
        "哥斯达黎加",
        "洪都拉斯",
        "塞尔维亚",
        "巴基斯坦",
        "阿联酋",
        "牙买加",
        "希腊",
        "以色列",
        "匈牙利",
        "other",
        "unknow nation",
      ],
      languages: [
        "中文",
        "英文",
        "日语",
        "韩语",
        "印尼语",
        "葡萄牙语",
        "西班牙语",
        "俄语",
        "菲律宾语",
        "土耳其语",
        "阿拉伯语",
        "法语",
        "泰语",
        "意大利语",
        "德语",
        "荷兰语",
        "罗马尼亚语",
        "瑞典语",
        "匈牙利语",
        "芬兰语",
        "波斯语",
        "波兰语",
        "马来语",
        "乌克兰语",
        "捷克语",
        "越南语",
        "乌尔都语",
        "希腊语",
        "希伯来语",
        "other",
      ],
      listInfo: {
        pageSize: 999999,
        pageNum: 1,
        order: "DESC",
        attribute: "create_at",
      },
      categoryKeys: [],
    };
  },
  created() {
    // this.key = this.appId;
    console.log("pushId+" + this.appId);
    this.userName = localStorage.getItem("username");
    this.role = localStorage.getItem("role");
    console.log("role+" + this.role);

    if (this.pushId) {
      console.log("pushId+" + this.pushId);
      this.getPushInfo(this.pushId);
      this.getStyle();
      debugger;
      console.log(this.form.targetKind);
    }
    if (this.isApp == 3) {
      this.form.checkedApps = ["Paint Color", "Coloring Games", "Fun Color"];
      this.appList = ["Paint Color", "Coloring Games", "Fun Color"];
    } else {
      // this.getAppGroup();
      this.getAppList();
    }
    if (this.key || this.isApp == 3) {
      this.themeKey = this.key;
      this.getPushkey(this.key, this.isApp);
    }
    if (this.form.targetKind == 117) {
      this.lableName = "News活动key";
    }
  },

  methods: {
    changable() {
      if (this.form.targetKind == 117) {
        this.activityList = this.newList;
        this.lableName = "News活动key";
      } else {
        this.lableName = "涂色活动key";
      }
    },
    testPush(type) {
      this.savePush(type);
    },
    changeColor(app) {
      if (app === "Common Group") {
        return 1;
      } else if (app === "Dark Group") {
        return 5;
      } else if (app === "Sexy Group") {
        return 3;
      } else if (app === "Black Group") {
        return 4;
      } else {
        return 2;
      }
    },
    getCategory(e) {
      if (e == "") {
        this.getListCategory();
      } else if (e.target.tagName === "INPUT") {
        return;
      } else {
        appApi
          .getList(this.listInfo)
          .then((response) => {
            // var list = [{ appId: null, appName: "全部" }];
            response.data.appList.forEach((e) => {
              if (
                !this.blackList.indexOf(e.appName) == -1 &&
                !this.sexyList.indexOf(e.appName) == -1 &&
                !this.commonList.indexOf(e.appName) == -1 &&
                !this.darkList.indexOf(e.appName) == -1
              ) {
                this.noList.push(e.appName);
              }
              if (e.appId === this.appId) {
                // this.appList.push(e.appName);
                this.form.checkedApps.push(e.appName);
                this.getListCategory();
              }
            });
            // this.appList = Array.from(list.concat(response.data.appList));
            this.listLoading = false;
          })
          .catch((res) => {
            console.log(res);
          });
      }
    },
    getActivity(e) {
      if (e == "") {
        if (this.form.targetKind == 117) {
          this.getNewList();
        } else {
          this.getListActivity();
        }
      } else if (e.target.tagName === "INPUT") {
        return;
      } else {
        if (this.form.targetKind == 117) {
          this.getNewList();
        } else {
          appApi
            .getList()
            .then((response) => {
              // var list = [{ appId: null, appName: "全部" }];
              this.getListActivity();
              // this.appList = Array.from(list.concat(response.data.appList));
              this.listLoading = false;
            })
            .catch((res) => {
              console.log(res);
            });
        }
      }
    },
    getNewList() {
      pushApi
        .getNewList()
        .then((response) => {
          response.data.newList.forEach((element) => {
            var name = "";
            if (typeof element.title == "string") {
              try {
                var obj = JSON.parse(element.title);
                // 等于这个条件说明就是JSON字符串 会返回true
                if (typeof obj == "object" && obj) {
                  name = obj[0].content;
                } else {
                  name = element.title;
                }
              } catch (e) {
                name = element.title;
                console.log("error:" + element.title + "!!!" + e);
              }
            }
            this.newList.push({
              label: element.key + "--" + name,
              value: element.key,
            });
          });
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getListCategory() {
      this.categoryKeys = [];
      var appList = this.form.checkedApps;
      pushApi
        .getCategoryList({ appList: appList.toString() })
        .then((response) => {
          response.data.categoryList.forEach((element) => {
            this.categoryKeys.push({
              lable: element.name + "--" + element.key,
              value: element.name + "--" + element.key,
            });
          });
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getListActivity() {
      this.activityList = [];
      var auto = this.data2;
      if (auto === "") {
        auto = 0;
      }
      const autoPushTime = this.formateDateToTimeStmap(auto);
      debugger;

      pushApi
        .getActivityList({ autoPushTime: autoPushTime })
        .then((response) => {
          response.data.activityList.forEach((element) => {
            var name = "";
            if (typeof element.name == "string") {
              try {
                var obj = JSON.parse(element.name);
                // 等于这个条件说明就是JSON字符串 会返回true
                if (typeof obj == "object" && obj) {
                  name = obj[0].content;
                } else {
                  name = element.name;
                }
              } catch (e) {
                name = element.name;
                console.log("error:" + element.name + "!!!" + e);
              }
            }
            this.activityList.push({
              label: element.key + "--" + name,
              value: element.key,
            });
          });
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    querySearch(queryString, cb) {
      var activityList = this.activityList;
      var results = queryString
        ? activityList.filter(this.createFilter(queryString))
        : activityList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (activity) => {
        return (
          activity.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    addBanner() {
      this.banners.push({
        banner: "",
      });
    },
    delDays(index) {
      MessageBox.confirm("确定删除组吗?", "提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => this.banners.splice(index, 1));
    },
    addTitles() {
      var titles = [];
      var subtitles = [];
      for (var i = 0; i < this.form.checkedLanguages.length; i++) {
        titles.push({
          name: this.form.checkedLanguages[i],
          value: "",
        });
        subtitles.push({
          name: this.form.checkedLanguages[i],
          value: "",
        });
      }
      var pushTitles = titles;
      var subtitles = subtitles;
      this.form.titles.push({
        pushTitles: pushTitles,
        pushSubtitles: subtitles,
      });
    },
    delTitles(index) {
      console.log(index);
      MessageBox.confirm("确定删除主/副标题嘛吗?", "提示", {
        confirmButtonText: "确定",
        showCancelButton: true,
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => this.form.titles.splice(index, 1));
    },
    getPushkey(key, isApp) {
      var themeInfo = {
        key: key,
        isApp: isApp,
      };
      pushApi
        .getThemePushByKey(themeInfo)
        .then((response) => {
          // var list = [{ appId: null, appName: "全部" }];
          // this.appList = Array.from(list.concat(response.data.appList));
          var pushId = parseInt(response.message);
          if (response.message != "") {
            this.pushId = pushId;
            this.getPushInfo(pushId);
          }
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getAppList() {
      appApi
        .getList(this.listInfo)
        .then((response) => {
          // var list = [{ appId: null, appName: "全部" }];
          response.data.appList.forEach((e) => {
            this.appList.push(e.appName);
            if (
              this.blackList.indexOf(e.appName) == -1 &&
              this.sexyList.indexOf(e.appName) == -1 &&
              this.commonList.indexOf(e.appName) == -1 &&
              this.darkList.indexOf(e.appName) == -1
            ) {
              this.noList.push(e.appName);
            }
            if (e.appId === this.appId) {
              this.form.checkedApps.push(e.appName);
            }
          });

          // this.appList = Array.from(list.concat(response.data.appList));
          this.listLoading = false;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getStyle() {
      console.log(123213);
    },
    formatData: function (value) {
      if (value == null || value == "") {
        return "";
      }
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d}");
    },
    formatDateToSecond: function (value) {
      const date = new Date(value * 1000);
      return parseTime(date, "{y}-{m}-{d} {h}:{i}:{s}");
    },
    getPushInfo(pushId) {
      pushApi.getPushById({ pushId: pushId }).then((response) => {
        if (response.status === 200) {
          const info = {};
          // this.form = response.data;
          this.isApp1 = response.data.isApp;
          this.appId = response.data.appId;
          this.form.checkedCities = response.data.countries;
          this.form.checkedLanguages = response.data.languages;
          this.form.checkedApps = [];
          this.form.checkedApps = response.data.appNames;
          this.form = Object.assign({}, this.form);
          this.form.notifyType = response.data.notifyType;
          this.form.badge = response.data.badge;
          this.form.pushType = response.data.pushType;
          this.form.pushKind = response.data.pushKind;
          this.form.pushRatio = response.data.pushRatio;
          this.form.pushTitle = response.data.pushTitle;
          this.form.pushName = response.data.pushName;
          this.form.pushSubtitle = response.data.pushSubtitle;

          this.form.targetKind = response.data.targetInfo.targetKind;
          // if (this.form.targetKind == 70) {
          //   this.getCategory("");
          // }
          if (this.form.targetKind == 112 || this.form.targetKind == 117) {
            this.getActivity("");
          }
          this.form.paintName =
            response.data.targetInfo.paintName +
            "--" +
            response.data.targetInfo.paintKey;
          this.form.paintResourceKey =
            response.data.targetInfo.paintResourceKey;
          this.form.paintKey = response.data.targetInfo.paintKey;
          this.data = this.formatDateToSecond(response.data.startTime);
          this.data1 = this.formatDateToSecond(response.data.endTime);
          this.data2 = this.formatDateToSecond(response.data.autoPushTime);
          this.form.themeName = response.data.targetInfo.themeName;
          this.form.themeKey = response.data.targetInfo.themeKey;
          this.form.imageKey = response.data.targetInfo.imageKey;
          this.form.parameter1 = response.data.targetInfo.parameter1;
          this.form.parameter2 = response.data.targetInfo.parameter2;
          this.form.duid = response.data.targetInfo.duid;
          this.form.gcmId = response.data.targetInfo.gcmId;
          this.pushAppId = response.data.pushAppId;
          this.form.pushTitles = response.data.pushTitles;
          this.form.pushSubtitles = response.data.pushSubTitles;
          this.form.pushSubtitles = response.data.pushSubTitles;
          this.form.styleContent = response.data.styleContents;
          this.form.titles = response.data.titles;
          this.banners = response.data.banners;
          this.themeKey = response.data.themeKey;
          for (var i = 0; i < this.form.styleContent.length; i++) {
            if (this.form.styleContent[i].pushStyle == 1) {
              this.checked1 = true;
              this.pushRatio1 = this.form.styleContent[i].pushRatio;
              this.form.pushIcon = response.data.pushIcon;
              this.form.pushBanner = response.data.pushBanner;
            }
            if (this.form.styleContent[i].pushStyle == 2) {
              this.checked2 = true;
              this.pushRatio2 = this.form.styleContent[i].pushRatio;
              this.form.pushIcon = response.data.pushIcon;
              this.form.pushBanner2 = response.data.pushBanner2;
            }
            if (this.form.styleContent[i].pushStyle == 3) {
              this.checked3 = true;
              this.pushRatio3 = this.form.styleContent[i].pushRatio;
              this.form.pushBanner3 = response.data.pushBanner3;
            }
            if (this.form.styleContent[i].pushStyle == 4) {
              this.checked4 = true;
              this.pushRatio4 = this.form.styleContent[i].pushRatio;
              this.form.pushBanner4 = response.data.pushBanner4;
            }
            if (this.form.styleContent[i].pushStyle == 5) {
              this.checked5 = true;
              this.pushRatio5 = this.form.styleContent[i].pushRatio;
              this.form.pushBanner5 = response.data.pushBanner5;
            }
            if (this.form.styleContent[i].pushStyle == 6) {
              this.checked6 = true;
              this.pushRatio6 = this.form.styleContent[i].pushRatio;
              this.form.pushBanner6 = response.data.pushBanner6;
            }
          }
        }
      });
    },
    formateDateToTimeStmap(value) {
      var date = new Date(value);
      console.log(Date.parse(date));
      return Date.parse(date) / 1000;
    },
    onSubmit() {
      console.log("submit!");
    },
    savePush(type) {
      if (this.checkAppGroup.length > 0) {
        this.checkAppGroup.forEach((e) => {
          if (this.form.checkedApps.indexOf(e) == -1)
            this.form.checkedApps.push(e);
        });
      } else {
        if (this.form.checkedApps.indexOf("Sexy Group") != -1)
          this.form.checkedApps.splice(
            this.form.checkedApps.indexOf("Sexy Group"),
            1
          );
        if (this.form.checkedApps.indexOf("Dark Group") != -1) {
          this.form.checkedApps.splice(
            this.form.checkedApps.indexOf("Dark Group"),
            1
          );
        }
        if (this.form.checkedApps.indexOf("Black Group") != -1)
          this.form.checkedApps.splice(
            this.form.checkedApps.indexOf("Black Group"),
            1
          );
        if (this.form.checkedApps.indexOf("Common Group") != -1)
          this.form.checkedApps.splice(
            this.form.checkedApps.indexOf("Common Group"),
            1
          );
      }

      if (this.form.checkedApps.length == 0) {
        Message({
          message: "请选择推送产品",
          type: "error",
          duration: 3 * 1000,
        });
      } else {
        var start = this.data;
        var end = this.data1;
        var auto = this.data2;
        const autoPushTime = this.formateDateToTimeStmap(auto);
        const startTime = this.formateDateToTimeStmap(start);
        const endTime = this.formateDateToTimeStmap(end);

        var pushModel = {
          pushName: this.form.pushName,
          pushTitle: this.form.pushTitle,
          pushSubtitle: this.form.pushSubtitle,
          pushType: this.form.pushType,
          isApp: this.isApp,
          notifyType: this.form.notifyType,
          badge: this.form.badge,
          pushKind: this.form.pushKind,
          pushIcon: this.form.pushIcon,
          pushBanner: this.form.pushBanner,
          pushBanner2: this.form.pushBanner2,
          pushBanner3: this.form.pushBanner3,
          pushBanner4: this.form.pushBanner4,
          pushBanner6: this.form.pushBanner6,
          pushPeople: this.form.pushPeople,
          pushRatio: this.form.pushRatio,
          startTime: startTime,
          autoPushTime: autoPushTime,
          endTime: endTime,
          pushId: this.pushId,
          pushTitles: this.form.pushTitles,
          pushSubTitles: this.form.pushSubtitles,
          titles: this.form.titles,
          banners: this.banners,
          styleContents: this.form.styleContent,
        };
        if (this.isApp == 2) {
          delete pushModel["pushTitles"];
          delete pushModel["pushSubTitles"];
        } else {
          delete pushModel["titles"];
          delete pushModel["banners"];
        }
        if (this.form.pushType == 1) {
          delete pushModel["autoPushTime"];
        }
        // if (!this.flag) {
        this.form.styleContent = [];
        if (this.checked1) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle1,
            pushRatio: this.pushRatio1,
          });
        } else {
          delete pushModel["pushBanner"];
          if (!this.checked2) {
            delete pushModel["pushIcon"];
          }
        }
        if (this.checked2) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle2,
            pushRatio: this.pushRatio2,
          });
        } else {
          if (!this.checked1) {
            delete pushModel["pushIcon"];
          }
          delete pushModel["pushBanner2"];
        }

        if (this.checked3) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle3,
            pushRatio: this.pushRatio3,
          });
        } else {
          delete pushModel["pushBanner3"];
        }

        if (this.checked4) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle4,
            pushRatio: this.pushRatio4,
          });
        } else {
          delete pushModel["pushBanner4"];
        }

        if (this.checked5) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle5,
            pushRatio: this.pushRatio5,
          });
        } else {
          delete pushModel["pushBanner5"];
        }
        if (this.checked6) {
          this.form.styleContent.push({
            pushStyle: this.pushStyle6,
            pushRatio: this.pushRatio6,
          });
        } else {
          delete pushModel["pushBanner6"];
        }
        pushModel.styleContents = this.form.styleContent;
        // }

        let pushAppModel = {
          country: this.form.checkedCities.toString(),
          language: this.form.checkedLanguages.toString(),
          appList: this.form.checkedApps.toString(),
          appId: this.appId,
          pushId: this.pushId,
          id: this.pushAppId,
          themeKey: this.themeKey,
        };

        var pushContent = {
          targetKind: this.form.targetKind,
          paintName: this.form.paintName,
          paintKey: this.form.paintKey,
          paintResourceKey: this.form.paintResourceKey,
          themeName: this.form.themeName,
          themeKey: this.form.themeKey,
          imageKey: this.form.imageKey,
          parameter1: this.form.parameter1,
          parameter2: this.form.parameter2,
          imageKey: this.form.imageKey,
          duid: this.form.duid,
          gcmId: this.form.gcmId,
        };

        if (this.form.targetKind == 70) {
          delete pushContent["themeName"];
          delete pushContent["themeKey"];
          delete pushContent["imageKey"];
        } else if (this.form.targetKind == 71) {
          delete pushContent["paintName"];
          delete pushContent["paintKey"];
          delete pushContent["paintResourceKey"];
          delete pushContent["imageKey"];
        } else if (
          this.form.targetKind == 72 ||
          this.form.targetKind == 101 ||
          this.form.targetKind == 116
        ) {
          delete pushContent["paintName"];
          delete pushContent["paintKey"];
          delete pushContent["paintResourceKey"];
          delete pushContent["themeName"];
          delete pushContent["themeKey"];
          delete pushContent["imageKey"];
        }

        if (type == 1) {
          pushModel.pushId = 1;
        } else {
          if (this.flag) {
            debugger;
            if (type == 1) {
              pushModel.pushId = 1;
            } else {
              debugger;
              delete pushModel["pushId"];
            }
          }
        }

        // delete pushAppModel["appList"];

        if (this.appId == 1) {
          debugger;
          // delete pushAppModel["appList"];
        }
        var pushInfo = {
          pushModel,
          pushAppModel,
          pushContent,
        };
        if (this.form.titles <= 0 && this.isApp == 2) {
          Message({
            message: "标题不能为空",
            type: "error",
            duration: 3 * 1000,
          });
        } else {
          if (this.form.targetKind != 0) {
            if (this.form.pushType == 2 && this.isApp != 2) {
              var auto = this.data2;
              const autoPushTime = this.formateDateToTimeStmap(auto);
              const nowTime = new Date().getTime() / 1000;
              if (
                autoPushTime - nowTime >= 8 * 3600 ||
                this.role.indexOf("admin") != -1
              ) {
                pushApi.createPush(pushInfo).then((response) => {
                  if (response.status === 200) {
                    Message({
                      message: response.message,
                      type: "success",
                      duration: 1 * 1000,
                    });
                    if (type != 1)
                      setTimeout(() => {
                        this.$router.go(-1);
                      }, 500);
                  }
                });
              } else {
                Message({
                  message:
                    "自动推送时间需要提前8个小时创建，如有疑问请联系管理员(natasha.liao)",
                  type: "error",
                  duration: 3 * 1000,
                });
              }
            } else {
              pushApi.createPush(pushInfo).then((response) => {
                if (response.status === 200) {
                  Message({
                    message: response.message,
                    type: "success",
                    duration: 1 * 1000,
                  });
                  if (type != 1)
                    setTimeout(() => {
                      this.$router.go(-1);
                    }, 500);
                }
              });
            }
          } else {
            Message({
              message: "请选择目标类型",
              type: "error",
              duration: 3 * 1000,
            });
          }
        }
      }
    },
    updatePush() {},
    handleAvatarSuccess() {},
    handleCheckAllChange(val) {
      this.form.checkedCities = val ? this.cities : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkCountryAll = checkedCount === this.cities.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length;
    },
    handleCheckAppAllChange(val) {
      this.form.checkedApps = val ? this.appList : [];
      this.isAppIndeterminate = false;
      if (this.form.checkedApps.length != 0) {
        this.paintGroupFlag = true;
      } else {
        this.paintGroupFlag = false;
      }
    },
    handleCheckedAppGroupChange(value) {
      let checkedCount = value.length;
      this.checkAppAll = checkedCount === this.checkAppGroup.length;
    },
    handleCheckedAppChange(value) {
      let checkedCount = value.length;
      this.checkAppAll = checkedCount === this.appList.length;
      this.isAppIndeterminate =
        checkedCount > 0 && checkedCount < this.appList.length;
      if (
        this.form.checkedApps.indexOf("Dark Group") != -1 ||
        this.form.checkedApps.indexOf("Sexy Group") != -1 ||
        this.form.checkedApps.indexOf("Black Group") != -1 ||
        this.form.checkedApps.indexOf("Common Group") != -1
      ) {
        debugger;
        this.paintGroupFlag = true;
      } else {
        this.paintGroupFlag = false;
      }
    },
    handleCheckLanguageAllChange(val) {
      if (val) {
        this.form.checkedLanguages = val ? this.languages : [];
        this.isLanguageIndeterminate = false;
        var diff;
        var a = this.last;
        if (this.last) {
          diff = this.form.checkedLanguages.filter(function (val) {
            return a.indexOf(val) === -1;
          });
        }
        for (var i = 0; i < diff.length; i++) {
          this.form.pushSubtitles.push({
            name: diff[i],
            value: "1",
          });
          this.form.pushTitles.push({
            name: diff[i],
            value: "1",
          });
        }
        this.last = this.form.checkedLanguages;
      } else {
        this.form.checkedLanguages = val ? this.languages : [];
        this.last = this.form.checkedLanguages;
        this.form.pushSubtitles = [];
        this.form.pushTitles = [];
        this.isIndeterminate = false;
      }
    },
    handleCheckedLanguageChange(value) {
      let checkedCount = value.length;
      this.checkLanguageAll = checkedCount === this.languages.length;
      this.isLanguageIndeterminate =
        checkedCount > 0 && checkedCount < this.languages.length;
      if (checkedCount < this.last.length) {
        var values = value.toString().split(",");
        var diff = this.last.filter(function (val) {
          return values.indexOf(val) === -1;
        });
        this.last = this.form.checkedLanguages;
        if (this.form.pushSubtitles && this.form.pushSubtitles) {
          for (var i = 0; i < this.form.pushSubtitles.length; i++) {
            if (this.form.pushSubtitles[i].name == diff.toString()) {
              this.form.pushSubtitles.splice(i, 1);
              break;
            }
          }
          for (var i = 0; i < this.form.pushTitles.length; i++) {
            if (this.form.pushTitles[i].name == diff.toString()) {
              this.form.pushTitles.splice(i, 1);
              break;
            }
          }
        }
      } else {
        this.last = this.form.checkedLanguages;
        this.form.pushSubtitles.push({
          name: this.form.checkedLanguages[
            this.form.checkedLanguages.length - 1
          ],
          value: "",
        });
        this.form.pushTitles.push({
          name: this.form.checkedLanguages[
            this.form.checkedLanguages.length - 1
          ],
          value: "",
        });
      }
    },
    beforeAvatarUpload() {
      this.loading = true;
      if (this.dimension || this.fileSize) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const $vm = this;

        const checkFileSize = () => {
          // fileSize 沒設定不需要檢查
          return !$vm.fileSize || file.size / 1024 < $vm.fileSize;
        };

        const checkDimension = () => {
          //dimension 沒設定不需要檢查
          if (!this.dimension) {
            return true;
          }
          const hasOperator = this.dimension.indexOf("<") >= 0;
          if (hasOperator) {
            const width = Number(
              this.dimension.split("|")[0].replace(/</gi, "")
            );
            const height = Number(
              this.dimension.split("|")[1].replace(/</gi, "")
            );

            return img.width <= width && img.height <= height;
          } else {
            const width = Number(this.dimension.split("|")[0]);
            const height = Number(this.dimension.split("|")[1]);
            return img.width === width && img.height === height;
          }
        };

        return new Promise((resolve, reject) => {
          img.onload = function () {
            if (checkDimension() && checkFileSize()) {
              resolve();
            } else {
              Message({
                message: `图片${file.name}尺寸不符合要求，请重新上传`,
                type: "error",
                duration: 5 * 1000,
              });
              $vm.loading = false;
              reject();
            }
          };
        });
      }
    },
    uploadIconImage(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushIcon = res.data.message;
            console.log("====>" + this.form.pushIcon);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushBanner = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage2(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushBanner2 = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage3(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushBanner3 = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage4(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushBanner4 = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage5(param, location) {
      console.log("====>" + location);

      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.banners[location].banner = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
    uploadBannerImage6(param) {
      const formData = new FormData();
      formData.append("file", param.file);
      let url = "/global/uploadFile";
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      if (process.env.NODE_ENV === "development") {
        url = "/api/global/uploadFile";
      }
      axios
        .post(url, formData, config)
        .then((res) => {
          console.log("====>" + res.data.status);
          if (res.data.status === 200) {
            this.form.pushBanner6 = res.data.message;
            console.log("====>" + this.form.pushBanner);
          }
        })
        .catch((res) => {});
    },
  },
};
</script>
<style scoped>
.top {
  margin: 5% 15%;
  width: 60%;
}
.el-radio,
.checkbox,
.el-checkbox-group,
.trigger {
  float: left;
}
.el-col {
  margin: 0 -8%;
}

.selectInfo,
.pushInfo {
  margin: 5% 0;
}
.appGroup {
  text-align: left;
  font-weight: bolder;
  font-size: 22;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  box-sizing: border-box;
  font-size: 28px;
  color: #8c939d;
  width: 130px;
  height: 130px;
  line-height: 130px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
